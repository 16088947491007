import styled from 'styled-components'

export const ChatClientWrapper = styled.div<{ isShowChatView: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
  padding: 40px 20px;
  border-radius: 22px;
  background: #001721;

  font-family: 'Poppins', serif;
  font-weight: bold;
  font-size: 8px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #b9b9b9;

  @media (max-width: 780px) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    display: ${({ isShowChatView }) => (isShowChatView ? 'block' : 'none')};
  }
`
