import { Modal } from '../../components/Modal'
import { Flex } from '../../components/Flex'
import { motion } from 'framer-motion'
import styled from 'styled-components'

interface Props {
  onDismiss?: () => void
  onCallback?: () => void
}

const ResignModalWrapper = styled.div`
  .desc {
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #fff;
  }
  .resignBtn {
    width: 102px;
    height: 27px;
    border-radius: 13.5px;
    background: #bf4040;
    border: 1px solid #bf4040;
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
  }

  .cancelBtn {
    width: 102px;
    height: 27px;
    border-radius: 13.5px;
    background: transparent;
    border: 1px solid #fff;
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
  }
`

export default function ResignModal(props: Props) {
  const { onDismiss, onCallback } = props
  return (
    <Modal title={'Confirm to resign'} onDismiss={onDismiss}>
      <ResignModalWrapper>
        <div className={'desc'}>You will lose if you Resign</div>
        <Flex justifyContent={'space-between'} style={{ gridGap: '30px', marginTop: '24px' }}>
          <motion.button onClick={onDismiss} className={'cancelBtn'} whileHover={{ opacity: 0.65 }}>
            Cancel
          </motion.button>
          <motion.button
            className={'resignBtn'}
            whileHover={{ opacity: 0.65 }}
            onClick={() => {
              onCallback && onCallback()
              onDismiss && onDismiss()
            }}
          >
            Resign
          </motion.button>
        </Flex>
      </ResignModalWrapper>
    </Modal>
  )
}
