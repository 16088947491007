import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import { State } from 'state/types'
import useResignModal from '../../widgets/ResignModal/useResignModal'
import { useRequestDrawModal } from '../../widgets/DrawModal'
import { GameActionWrapper } from './styles'
import { useSocket } from 'contexts/SocketContext'

interface Props {
  offeringDraw: boolean
  roomId: string
  userAddress: string | null
  onResign: () => void
}

export default function GameActions({ offeringDraw, roomId, userAddress, onResign }: Props) {
  const { SocketIo } = useSocket()
  const app = useSelector((state: State) => state.app)
  const { onPresentResignModal } = useResignModal(() => {
    onResign()
    SocketIo?.emit('resign-game', {
      roomId,
      userAddress,
      userSig: app.gameSignature,
      auth: app.auth,
    })
  })

  const { onPresentRequestDrawModal } = useRequestDrawModal(() => {
    SocketIo?.emit('offer-draw', {
      roomId,
      userAddress,
      userSig: app.gameSignature,
      auth: app.auth,
    })
  })
  const handleResign = () => {
    onPresentResignModal()
  }

  const handleDraw = () => {
    onPresentRequestDrawModal()
  }

  return (
    <GameActionWrapper>
      <motion.button onClick={handleResign} whileHover={{ opacity: 0.65 }} className={'resignBtn'}>
        Resign
      </motion.button>
      <motion.button onClick={handleDraw} className={'drawBtn'} whileHover={{ opacity: 0.65 }}>
        {offeringDraw ? 'Offering Draw' : 'Offer Draw'}
      </motion.button>
    </GameActionWrapper>
  )
}
