import { formatBlockchainAddress } from '../../utils'
import cn from 'classnames'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { motion } from 'framer-motion'
import moment from 'moment'
import { useEffect, useState } from 'react'

export interface PlayerStatusType {
  address: string
  inRoom: boolean
  isReady: boolean
  joinTimeStamp: number | null
  isOwner: boolean
}

interface iProps {
  players: (PlayerStatusType | null)[]
  isOwner: boolean
  onKick: (address: string) => void
}

const timerProps = {
  size: 44,
  strokeWidth: 2,
}
export default function PlayerStatus(props: iProps) {
  const { players, isOwner, onKick } = props
  const [remainTime, setRemainTime] = useState(0)

  useEffect(() => {
    for (let i = 0; i < players.length; i++) {
      const player = players[i]
      if (player && !player.isOwner && player.joinTimeStamp) {
        const diffSeconds = player.joinTimeStamp + 30 - moment().unix()
        setRemainTime(diffSeconds > 0 ? diffSeconds : 0)
      }
    }
  }, [players])
  return (
    <div className={'userStatus'}>
      {players.map((player, idx) => {
        return (
          <table key={`player_${idx}`}>
            <thead>
              <tr className={'userStatus-header'}>
                <th style={{ width: '35%' }}>PLAYER {idx + 1}</th>
                <th style={{ width: '20%' }}>IN THE ROOM</th>
                <th style={{ width: '20%' }}>READY TO PLAY</th>
                <th style={{ width: '25%' }} />
              </tr>
            </thead>
            <tbody className={'userStatus-content active'}>
              <tr>
                <td>
                  {player ? (
                    <>
                      <img
                        className="playerImg"
                        src={'/img/player-img.svg'}
                        width={'40px'}
                        alt={'player'}
                      />
                      <span>{formatBlockchainAddress(player.address, 4, 4)}</span>
                    </>
                  ) : (
                    <span>No one got in yet.</span>
                  )}
                </td>
                <td>
                  <div
                    className={cn('joinStatus', {
                      active: player && player.inRoom,
                    })}
                  />
                </td>
                <td>
                  <div
                    className={cn('joinStatus', {
                      active: player && player.isReady,
                    })}
                  />
                </td>
                <td>
                  {isOwner &&
                    player &&
                    !player.isOwner &&
                    !player.isReady &&
                    (remainTime > 0 ? (
                      <CountdownCircleTimer
                        {...timerProps}
                        isPlaying={true}
                        duration={30}
                        initialRemainingTime={remainTime}
                        colors={['#2d6db2', '#A30000']}
                        colorsTime={[10, 0]}
                        trailStrokeWidth={2}
                        onComplete={() => {
                          setRemainTime(0)
                        }}
                      >
                        {({ remainingTime }) => {
                          return <span className={'remainingTime'}>{remainingTime}</span>
                        }}
                      </CountdownCircleTimer>
                    ) : (
                      <motion.button
                        onClick={() => onKick(player.address)}
                        className={'kickBtn'}
                        whileHover={{ opacity: 0.65 }}
                      >
                        Kick Off
                      </motion.button>
                    ))}
                </td>
              </tr>
            </tbody>
          </table>
        )
      })}
    </div>
  )
}
