import { switchNetwork } from '@wagmi/core'
import { message } from 'antd'
import LeaderBoard from 'components/LeadearBoard/LeadearBoard'
import useAuth from 'hook/useAuth'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { useAccount, useNetwork } from 'wagmi'
import { useEagerConnect } from '../../state/hooks'
import { State } from '../../state/types'
import { Gaming } from '../../views/Gaming'
import { Home } from '../../views/Home'
import { JoinRoom } from '../../views/JoinRoom'
import { RoomList } from '../../views/RoomList'
import Loading from '../Loading'
import ToastListener from '../ToastListener'
import { setUser } from 'state/app'
import { CURRENT_CHAIN } from '../../constants/chains'

const Start = () => {
  message.config({
    top: 100,
    duration: 2,
    maxCount: 1,
  })
  const app = useSelector((state: State) => state.app)
  const dispatch = useDispatch()
  const { logout } = useAuth()
  const { address } = useAccount()
  useEagerConnect()

  useEffect(() => {
    if (address) {
      dispatch(setUser(address.toString()))
    }
  }, [address, dispatch])

  const { chain } = useNetwork()
  useEffect(() => {
    if (chain && chain.unsupported) {
      switchNetwork({
        chainId: CURRENT_CHAIN,
      }).catch(() => {
        logout()
      })
    }
  }, [chain, logout])

  return (
    <>
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path="/leader-board" element={<LeaderBoard />} />
        <Route path={'/room-list/:gid/:amount/:rid/:creator'} element={<RoomList />} />
        <Route path={'/room-list'} element={<RoomList />} />
        <Route path={'/join-room'} element={<JoinRoom />} />
        <Route path={'/gaming'} element={<Gaming />} />
      </Routes>
      <ToastListener />
      <Loading isVisible={app.isLoading} />
    </>
  )
}

export default Start
