import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 22.833 29.229" {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_3"
            data-name="Rectangle 3"
            width="22.833"
            height="29.229"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_1" data-name="Group 1" clipPath="url(#clip-path)">
        <path
          id="Path_1"
          data-name="Path 1"
          d="M3.937,21.223a3.869,3.869,0,0,1,.871-2.445.558.558,0,0,0-.169-.815A6.166,6.166,0,0,1,1.3,13.524,9.64,9.64,0,0,1,1.6,9.14a2.661,2.661,0,0,0,.056-.955A11.4,11.4,0,0,1,1.83,1.609C2.363.036,3.235-.33,4.78.373A10.658,10.658,0,0,1,7.169,1.947a.51.51,0,0,0,.646.084,10.578,10.578,0,0,1,8.374.056A.465.465,0,0,0,16.722,2,11.593,11.593,0,0,1,19,.457,7.7,7.7,0,0,1,20.123.036,1.4,1.4,0,0,1,21.7.766,7.413,7.413,0,0,1,22.511,3.3a12.312,12.312,0,0,1-.281,5.17.634.634,0,0,0,.056.45,9.336,9.336,0,0,1,.506,3.934,6.227,6.227,0,0,1-3.428,5.058.516.516,0,0,0-.169.815,4.144,4.144,0,0,1,.815,3.316c0,.14-.084.225.084.365a2.872,2.872,0,0,1,1.264,2.81,6.07,6.07,0,0,1-.59,2.079,2.219,2.219,0,0,1-2.051,1.321h-.731a3.358,3.358,0,0,0-1.574.309,3.019,3.019,0,0,1-2.276.14c-.056,0-.084-.056-.14-.056-.506-.534-1.124-.421-1.77-.421h-.955a2.32,2.32,0,0,0-1.18.309,2.674,2.674,0,0,1-2.81-.14,1.018,1.018,0,0,0-.646-.169H5.23a2.279,2.279,0,0,1-2.051-1.321,4.978,4.978,0,0,1-.534-1.911.725.725,0,0,0-.309-.534A5.026,5.026,0,0,1,.79,17.879c.056-.056.084-.084.084-.14A.348.348,0,0,1,1.3,17.57c.169.056.225.169.281.365a4.205,4.205,0,0,0,1.264,2.5,2.893,2.893,0,0,0,1.1.787"
          transform="translate(0 0)"
          fill="#311f1b"
        />
        <path
          id="Path_2"
          data-name="Path 2"
          d="M6.414,17.43a8.538,8.538,0,0,1,.309-3.681,10.715,10.715,0,0,1,3.906-5.62c.281-.225.309-.421.084-.674A10.306,10.306,0,0,0,9.589,6.134c.169-.169.309-.056.45.056a9.016,9.016,0,0,1,1.461,1.1.881.881,0,0,0,.871.084,10.305,10.305,0,0,1,6.238-.646,12.842,12.842,0,0,1,2.079.674.611.611,0,0,0,.646-.084,11.011,11.011,0,0,1,1.461-1.1c.169-.14.365-.281.59-.14a15.615,15.615,0,0,0-1.1,1.236c-.309.45-.309.534.14.871a10.782,10.782,0,0,1,4.215,7.868,3.465,3.465,0,0,1-.056,1.321,1.806,1.806,0,0,1-1.686.9,3.948,3.948,0,0,1-2.726-.9A26.575,26.575,0,0,1,20.1,15.463a9.863,9.863,0,0,0-1.349-1.124,3.8,3.8,0,0,0-4.524.056,17.1,17.1,0,0,0-1.855,1.63c-.45.421-.871.9-1.349,1.264a3.971,3.971,0,0,1-2.81.983,2.1,2.1,0,0,1-1.8-.843"
          transform="translate(-4.556 -4.328)"
          fill="#f6a32b"
        />
        <path
          id="Path_3"
          data-name="Path 3"
          d="M6.61,36.632a3.473,3.473,0,0,0,1.321.674,3.67,3.67,0,0,0,3.2-.955c.815-.674,1.574-1.461,2.36-2.164a5.336,5.336,0,0,1,2.136-1.321,4.018,4.018,0,0,1,3.681.871c.955.759,1.8,1.686,2.754,2.529a3.8,3.8,0,0,0,3.4,1.1,2.733,2.733,0,0,0,1.264-.731,3.353,3.353,0,0,1-.14.731,13.444,13.444,0,0,1-.815,1.236,8.226,8.226,0,0,1-2.95,1.911,15.479,15.479,0,0,1-4.018.815,21.082,21.082,0,0,1-6.238-.309A8.969,8.969,0,0,1,7.959,38.8a4.848,4.848,0,0,1-1.124-1.489,2.12,2.12,0,0,1-.225-.674"
          transform="translate(-4.753 -23.53)"
          fill="#fff"
        />
        <path
          id="Path_4"
          data-name="Path 4"
          d="M7.31,49.127a6.928,6.928,0,0,1,.9,1.1A7.634,7.634,0,0,0,10.345,51.8a13.937,13.937,0,0,0,5.03,1.236,20.623,20.623,0,0,0,6.575-.45,8.874,8.874,0,0,0,4.243-2.3,7,7,0,0,0,.646-.871c.056-.084.084-.225.281-.225a5.424,5.424,0,0,1-2.529,3.344,11.529,11.529,0,0,1-4.665,1.489,19.409,19.409,0,0,1-7.418-.421,8.23,8.23,0,0,1-4.074-2.3A4.143,4.143,0,0,1,7.31,49.127"
          transform="translate(-5.256 -35.322)"
          fill="#ddd"
        />
        <path
          id="Path_5"
          data-name="Path 5"
          d="M49.155,78.179a4.155,4.155,0,0,1-.056-1.04V74.863c0-.084.056-.084.084-.056.084.084.056.225.084.365a32.435,32.435,0,0,0,2.585-2.613,3.444,3.444,0,0,0,.9-1.939c0-.056.056-.141.056-.169.169-.084.309-.141.506-.225.225,0,.225.225.281.365a4.6,4.6,0,0,1,.056,1.855,11.563,11.563,0,0,1-.983,3.175,11.139,11.139,0,0,1-.983,1.911,1.482,1.482,0,0,1-.309.422.238.238,0,0,1-.169.056,3.518,3.518,0,0,0-1.77.225.421.421,0,0,1-.281-.056"
          transform="translate(-35.299 -50.493)"
          fill="#f6a32b"
        />
        <path
          id="Path_6"
          data-name="Path 6"
          d="M23.9,75.522c0,.871.056,1.714,0,2.585a.254.254,0,0,1-.281,0,2.963,2.963,0,0,0-1.714-.169.3.3,0,0,1-.365-.056,2.651,2.651,0,0,0-.281-.646,17.49,17.49,0,0,1-1.77-4.384,5.384,5.384,0,0,1-.084-2.22c.056-.169.084-.422.281-.506.169.084.309.141.506.225a.4.4,0,0,1,.14.309,3.2,3.2,0,0,0,.731,1.63,24.367,24.367,0,0,0,2.5,2.613,1.465,1.465,0,0,1,.337.618"
          transform="translate(-13.896 -50.421)"
          fill="#f6a32b"
        />
        <path
          id="Path_7"
          data-name="Path 7"
          d="M31.308,78.8V76.717c0-.309-.14-.506-.45-.45-.14-.084-.169-.281-.225-.422a8.107,8.107,0,0,0-1.264-2.388,1.053,1.053,0,0,0-.59-.506c-.084-.056-.169-.056-.169-.225.731.084,1.461.281,2.164.309.281,0,.169.14.084.309a1.646,1.646,0,0,0,.421,1.911,1.764,1.764,0,0,0,1.939.365,1.8,1.8,0,0,0,.955-2.22c-.14-.365,0-.365.281-.365.534-.056,1.1-.141,1.686-.225.056.141-.056.169-.084.281a7.167,7.167,0,0,0-1.574,2.95c-.056.084-.056.169-.14.225-.422-.056-.506.056-.506.45,0,.674,0,1.349-.056,2.051a1.948,1.948,0,0,1-2.473.028"
          transform="translate(-20.571 -52.291)"
          fill="#ddd"
        />
        <path
          id="Path_8"
          data-name="Path 8"
          d="M24.12,71.633c.871.309,1.18,1.1,1.574,1.8a5.122,5.122,0,0,1,.646,1.686c-.281.141-.225.421-.225.646v.506a40.18,40.18,0,0,1-2.979-3.2,3.07,3.07,0,0,1-.731-1.939c.59.14,1.124.337,1.714.506"
          transform="translate(-16.109 -51.14)"
          fill="#fff"
        />
        <path
          id="Path_9"
          data-name="Path 9"
          d="M48.51,75.245a5.456,5.456,0,0,1,.9-2.164,7.112,7.112,0,0,1,.955-1.264c.674-.169,1.4-.365,2.051-.59a1.868,1.868,0,0,1-.365,1.461,13.856,13.856,0,0,1-2.22,2.585,9.588,9.588,0,0,0-1.04,1.1c-.141-.225,0-.45-.084-.674a.464.464,0,0,0-.2-.45"
          transform="translate(-34.879 -51.212)"
          fill="#fff"
        />
        <path
          id="Path_10"
          data-name="Path 10"
          d="M56.91,77.038a15.624,15.624,0,0,0,2.136-5.03,4.363,4.363,0,0,0-.084-2.613c.056-.056.14-.084.169-.141.534-.281.534-.281.759.281a4.564,4.564,0,0,1,.084,2.81,15.741,15.741,0,0,1-2.051,4.665,3.181,3.181,0,0,1-.281.365c-.281-.028-.562-.028-.731-.337"
          transform="translate(-40.918 -49.662)"
          fill="#ef8105"
        />
        <path
          id="Path_11"
          data-name="Path 11"
          d="M17.554,69.445a5.744,5.744,0,0,0,.281,3.934,14.024,14.024,0,0,0,1.63,3.4c.084.14.084.225-.056.365a.449.449,0,0,1-.534.309A9.507,9.507,0,0,1,17.329,74.7a9.452,9.452,0,0,1-.955-3.4,3.54,3.54,0,0,1,.309-1.8c.225-.45.225-.45.646-.169.084,0,.14.056.225.112"
          transform="translate(-11.763 -49.711)"
          fill="#ef8105"
        />
        <path
          id="Path_12"
          data-name="Path 12"
          d="M62.21,87.14a18.86,18.86,0,0,0,2.136-4.665c.084-.281.169-.169.309-.056a2.7,2.7,0,0,1,.646,2.051,4.642,4.642,0,0,1-.506,1.714,1.638,1.638,0,0,1-1.264.955,10.331,10.331,0,0,1-1.321,0"
          transform="translate(-44.729 -59.173)"
          fill="#ef8005"
        />
        <path
          id="Path_13"
          data-name="Path 13"
          d="M14.006,87.187A1.775,1.775,0,0,1,11.927,85.7a3.332,3.332,0,0,1-.14-2.388,1.9,1.9,0,0,1,.506-.815c.14-.14.225-.225.281.056a17.5,17.5,0,0,0,2,4.44c.084.169.084.225-.14.225a2.126,2.126,0,0,0-.421-.028"
          transform="translate(-8.356 -59.22)"
          fill="#ef8005"
        />
        <path
          id="Path_14"
          data-name="Path 14"
          d="M68.025,9.813a13.133,13.133,0,0,0-2.3-2.669c-.506-.421-.506-.45-.056-.955a8.441,8.441,0,0,1,1.714-1.461c.14.084.169.225.225.365a8.8,8.8,0,0,1,.506,2.585,7.228,7.228,0,0,1,.056,1.349c.084.337.084.59-.14.787"
          transform="translate(-46.975 -3.399)"
          fill="#c65017"
        />
        <path
          id="Path_15"
          data-name="Path 15"
          d="M9.994,4.927a6.666,6.666,0,0,1,2,1.77c.084.14.14.225,0,.365A12.1,12.1,0,0,0,9.4,9.957a.482.482,0,0,1-.225-.45,12.815,12.815,0,0,1,.45-3.99c.056-.225.084-.45.365-.59"
          transform="translate(-6.591 -3.543)"
          fill="#c65017"
        />
        <path
          id="Path_16"
          data-name="Path 16"
          d="M28.515,3.782a4.269,4.269,0,0,0-1.545.983,1.246,1.246,0,0,1-1.63.309A8.858,8.858,0,0,0,20.591,4.4a8.649,8.649,0,0,0-3.344.955c-.14.084-.225.056-.365-.056a20.977,20.977,0,0,0-1.77-1.349.662.662,0,0,0-.365-.14c-.421-.309-.759-.646-1.236-.955a2.293,2.293,0,0,1,1.1.421,15.273,15.273,0,0,1,2.051,1.489.563.563,0,0,0,.731.14,9.9,9.9,0,0,1,8.458,0,.55.55,0,0,0,.674-.084,10.4,10.4,0,0,1,2.726-1.855c.141-.056.225-.084.365-.14.056,0,.056,0,.14.056-.478.309-.843.618-1.236.9"
          transform="translate(-9.714 -2.033)"
          fill="#fccb5f"
        />
        <path
          id="Path_17"
          data-name="Path 17"
          d="M2.82,67.958a3.035,3.035,0,0,1,.225-.731,5.385,5.385,0,0,0,1.321,2.051,4.3,4.3,0,0,0,1.4.955c.365.169.365.365.056.59a2.511,2.511,0,0,0-.815.9c-.169.056-.309-.084-.421-.169A4.62,4.62,0,0,1,2.9,68.885a1.545,1.545,0,0,1-.084-.927"
          transform="translate(-2.002 -48.336)"
          fill="#ef8005"
        />
        <path
          id="Path_18"
          data-name="Path 18"
          d="M8.467,4.179A10.824,10.824,0,0,0,7.793,8.7a3.79,3.79,0,0,0,.084.506c-.169.365-.365.731-.59,1.124a2.028,2.028,0,0,1-.169-.983,11.161,11.161,0,0,1,.337-4.412A3.9,3.9,0,0,0,7.624,4.4c.253-.646.253-.646.843-.225"
          transform="translate(-5.064 -2.794)"
          fill="#de6c0f"
        />
        <path
          id="Path_19"
          data-name="Path 19"
          d="M73.4,9.205a12.143,12.143,0,0,0-.281-4.018,7.288,7.288,0,0,1-.309-1.04l.056-.056c.506-.309.506-.309.731.225a9.408,9.408,0,0,1,.646,3.316,13.158,13.158,0,0,1-.141,2.36,2.723,2.723,0,0,1-.084.309c-.2-.365-.393-.731-.618-1.1"
          transform="translate(-52.351 -2.791)"
          fill="#de6c0f"
        />
        <path
          id="Path_20"
          data-name="Path 20"
          d="M38.1,94.327a2.36,2.36,0,0,0,2.36,0c0,.421.056.759.056,1.18,0,.225-.056.281-.225.281h-2c-.169,0-.281-.056-.225-.225.028-.421.028-.815.028-1.236"
          transform="translate(-27.364 -67.821)"
          fill="#ee8007"
        />
        <path
          id="Path_21"
          data-name="Path 21"
          d="M2.516,69.827a4.85,4.85,0,0,0,2.136,3.765,4.489,4.489,0,0,0-.225.731c-.056.169-.084.225-.225.084a4.316,4.316,0,0,1-1.686-4.58"
          transform="translate(-1.698 -50.206)"
          fill="#ddd"
        />
        <path
          id="Path_22"
          data-name="Path 22"
          d="M39.409,75.849a1.065,1.065,0,1,1,.9-1.911,1.2,1.2,0,0,1-.9,1.911"
          transform="translate(-27.801 -53.052)"
          fill="#f8e035"
        />
        <path
          id="Path_23"
          data-name="Path 23"
          d="M19.773,65.945c-.056-.281.056-.309.281-.225a15.591,15.591,0,0,0,4.524.9c.45,0,.506.056.45.59-.056.421-.309.169-.506.225-.056,0-.14-.056-.169-.056.056-.084.084-.084.169-.141a12.348,12.348,0,0,1-4.749-1.293"
          transform="translate(-14.207 -47.223)"
          fill="#b11922"
        />
        <path
          id="Path_24"
          data-name="Path 24"
          d="M46.856,67.284c-.59.14-.646.084-.646-.45,0-.281.14-.309.365-.309a16.072,16.072,0,0,0,3.484-.534,9.485,9.485,0,0,0,1.18-.365c.14-.056.281-.084.225.141a2.274,2.274,0,0,1-.955.59,14.718,14.718,0,0,1-3.625.815c-.056,0-.14-.056-.084.056,0,.028.056.056.056.056"
          transform="translate(-33.225 -47.157)"
          fill="#b11922"
        />
        <path
          id="Path_25"
          data-name="Path 25"
          d="M28.121,97.248a2.847,2.847,0,0,1,2.079.309c0,.421-.14.731-.534.815a4.555,4.555,0,0,1-1.236,0c0-.169.169-.169.281-.169A4.3,4.3,0,0,1,28.2,97.7c-.084-.141-.169-.281-.084-.45"
          transform="translate(-20.194 -69.871)"
          fill="#fdfdfb"
        />
        <path
          id="Path_26"
          data-name="Path 26"
          d="M49.31,97.542a2.631,2.631,0,0,1,2.051-.309c.169.141.056.225,0,.365a2.789,2.789,0,0,1-.674.731c-.225.169-.506.056-.759.056a.743.743,0,0,1-.618-.843"
          transform="translate(-35.454 -69.856)"
          fill="#fffdfc"
        />
        <path
          id="Path_27"
          data-name="Path 27"
          d="M47.216,67.816c-.169-.056-.506.084-.506-.084,0-.225.309-.084.45-.14a18.29,18.29,0,0,0,3.484-.731c.421-.14.759-.365,1.18-.534.056.225-.14.281-.309.365a10.834,10.834,0,0,1-3.569,1.04,3.24,3.24,0,0,1-.731.084"
          transform="translate(-33.585 -47.689)"
          fill="#d11f2a"
        />
        <path
          id="Path_28"
          data-name="Path 28"
          d="M19.81,66.538c.14-.084.225,0,.365.084a10.022,10.022,0,0,0,4.018,1.04,5.527,5.527,0,0,1,.674.084c.084,0,.169,0,.14.14,0,.084-.084.084-.169.084h-.421a11.176,11.176,0,0,1-4.355-1.18c-.112-.056-.253-.112-.253-.253"
          transform="translate(-14.243 -47.816)"
          fill="#d51f29"
        />
        <path
          id="Path_29"
          data-name="Path 29"
          d="M51.21,98.551c.759.084,1.04-.534,1.461-.983v-.141h.14a2.226,2.226,0,0,1,.731.365,2.109,2.109,0,0,1-2.332.759"
          transform="translate(-36.82 -70.05)"
          fill="#dadad9"
        />
        <path
          id="Path_30"
          data-name="Path 30"
          d="M26.053,97.427c.14.45.534.646.815.983-.14.14-.421-.056-.534.14a1.646,1.646,0,0,1-1.124-.759c.169-.084.365-.225.534-.309a.51.51,0,0,0,.309-.056"
          transform="translate(-18.126 -70.05)"
          fill="#dadada"
        />
        <path
          id="Path_31"
          data-name="Path 31"
          d="M41.31,76.166c.955-.421,1.1-.674.871-1.939a1.058,1.058,0,0,1,.45,1.349,1.081,1.081,0,0,1-1.321.59"
          transform="translate(-29.702 -53.369)"
          fill="#faf9f7"
        />
        <path
          id="Path_32"
          data-name="Path 32"
          d="M41.36,69.8c-.225,0-.45.141-.45-.281,0-.365.169-.309.421-.309s.45-.084.45.309c0,.422-.281.225-.421.281"
          transform="translate(-29.414 -49.755)"
          fill="#c4c4c3"
        />
        <path
          id="Path_33"
          data-name="Path 33"
          d="M29.31,101.363a9.078,9.078,0,0,1,1.236,0,3.054,3.054,0,0,1-1.236,0"
          transform="translate(-21.074 -72.862)"
          fill="#dadada"
        />
        <path
          id="Path_34"
          data-name="Path 34"
          d="M18.21,30.406a1.446,1.446,0,0,1,1.4-1.489,1.5,1.5,0,0,1,1.63,1.321.866.866,0,0,1-.534,1.04,2.576,2.576,0,0,1-1.77.225.987.987,0,0,1-.731-1.1"
          transform="translate(-13.093 -20.789)"
          fill="#311f1b"
        />
        <path
          id="Path_35"
          data-name="Path 35"
          d="M57.6,31.427a5.577,5.577,0,0,1-.9-.169,1.1,1.1,0,0,1-.731-1.545,1.519,1.519,0,0,1,1.714-.955A1.539,1.539,0,0,1,58.917,30.3c0,.759-.45,1.124-1.321,1.124"
          transform="translate(-40.171 -20.658)"
          fill="#311f1b"
        />
        <path
          id="Path_36"
          data-name="Path 36"
          d="M26.015,20.584c0,.534-.45.9-1.1.871a.155.155,0,0,1-.14-.056c-.674-.084-1.04-.421-.983-.9a1.247,1.247,0,0,1,1.321-.983,1.006,1.006,0,0,1,.9,1.068"
          transform="translate(-17.105 -14.029)"
          fill="#fffffd"
        />
        <path
          id="Path_37"
          data-name="Path 37"
          d="M53.21,20.667a1.043,1.043,0,0,1,.955-1.04,1.236,1.236,0,0,1,1.264,1.04.7.7,0,0,1-.309.646,1.641,1.641,0,0,1-1.489.14.939.939,0,0,1-.422-.787"
          transform="translate(-38.258 -14.112)"
          fill="#fffffd"
        />
        <path
          id="Path_38"
          data-name="Path 38"
          d="M32.457,36.536a2.115,2.115,0,0,1,2.079-.731,1.26,1.26,0,0,1,.534.14,1.063,1.063,0,0,1,.871,1.236,1.6,1.6,0,0,1-.815,1.18c-.309.169-.309.169-.056.506.59.309.9.281,1.321-.225a5.42,5.42,0,0,0,.365-.59.3.3,0,0,1,.45-.14.327.327,0,0,1,.14.45,2.389,2.389,0,0,1-.983,1.18.547.547,0,0,0-.281.365,2.457,2.457,0,0,1-.815,1.574,1.579,1.579,0,0,1-2.276.056,2.747,2.747,0,0,1-.815-1.63.721.721,0,0,0-.281-.45,2.512,2.512,0,0,1-.9-1.124.307.307,0,0,1,.14-.45.3.3,0,0,1,.45.169,2.089,2.089,0,0,0,.365.59.885.885,0,0,0,1.4.084c.169-.225,0-.281-.141-.421a1.49,1.49,0,0,1-.759-1.77"
          transform="translate(-22.253 -25.738)"
          fill="#311f1b"
        />
        <path
          id="Path_39"
          data-name="Path 39"
          d="M36.063,38.427a1.805,1.805,0,0,0,1.349,1.911.863.863,0,0,1-.421.281,1.66,1.66,0,0,1-.927-2.192"
          transform="translate(-25.859 -27.629)"
          fill="#cccdce"
        />
        <path
          id="Path_40"
          data-name="Path 40"
          d="M44.244,42.213a1.638,1.638,0,0,1-.534-.365,2.043,2.043,0,0,0,1.461-1.321.779.779,0,0,1-.14.871,2.462,2.462,0,0,1-.674.646c-.112.028-.2.028-.112.169"
          transform="translate(-31.428 -29.139)"
          fill="#cdcfcf"
        />
        <path
          id="Path_41"
          data-name="Path 41"
          d="M23.016,30.727c.281.056.45.14.45.45a.459.459,0,0,1-.45.506.5.5,0,0,1-.506-.506.445.445,0,0,1,.506-.45"
          transform="translate(-16.185 -22.093)"
          fill="#faf9f9"
        />
        <path
          id="Path_42"
          data-name="Path 42"
          d="M59.965,31.117c-.056.225-.169.421-.45.421a.5.5,0,0,1-.506-.506.492.492,0,0,1,.45-.506.528.528,0,0,1,.506.59"
          transform="translate(-42.428 -21.949)"
          fill="#f8f8f8"
        />
        <path
          id="Path_43"
          data-name="Path 43"
          d="M39.6,37.871a2.684,2.684,0,0,1-.815-.084c-.365-.169-.365-.45-.056-.674a2.034,2.034,0,0,1,1.714.056c.281.169.281.45-.056.59a.923.923,0,0,1-.787.112"
          transform="translate(-27.688 -26.567)"
          fill="#5b3d38"
        />
        <path
          id="Path_44"
          data-name="Path 44"
          d="M41.14,52.463a.88.88,0,0,1-1.63,0c.056-.14.141-.169.225-.225a1.6,1.6,0,0,1,1.4.225"
          transform="translate(-28.408 -37.507)"
          fill="#ef857f"
        />
        <path
          id="Path_45"
          data-name="Path 45"
          d="M40.78,52.113a2.324,2.324,0,0,0-1.63,0c-.169-.14-.225-.281.056-.309a2.689,2.689,0,0,1,1.63,0c.253.084.028.225-.056.309"
          transform="translate(-28.048 -37.156)"
          fill="#f5ada6"
        />
      </g>
    </Svg>
  )
}

export default Icon
