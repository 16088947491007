import style from './loading.module.scss'
import loadingJson from './loading.json'
import lottie from 'lottie-web'
import { useEffect } from 'react'
import cn from 'classnames'

const Loading = (props) => {
  const { isVisible } = props
  const id = 'loading-container'

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.getElementById(id),
      animationData: loadingJson,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'animation',
    })
    animation.play()

    return () => {
      lottie.destroy('animation')
    }
  }, [])
  return (
    <div
      className={cn(style.loadingContainer, {
        [style.containerVisible]: isVisible,
      })}
    >
      <div id={id} className={style.loader} />
    </div>
  )
}

export default Loading
