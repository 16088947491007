import { abi as ChessABI } from 'abi/chess'
import { CHESS_CONTRACT_ADDRESS, DEPOSIT_TOKEN_DECIMALS } from 'constants/address'
import { getAddress, getDepositTokenAddress } from 'helpers/addressHelpers'
import { useEffect, useMemo, useState } from 'react'
import { formatUnits, parseUnits } from 'viem'
import {
  useAccount,
  useContractReads,
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi'

interface ERC20Interface {
  balances?: string
  withdrawal?: bigint
}

const useChessContract = (depositAmt = '0') => {
  // Hook data initialization
  const address = getAddress(CHESS_CONTRACT_ADDRESS)
  const { address: userAddress } = useAccount()
  const { readCalls, chessContract } = useMemo(() => {
    const chessContract = {
      address,
      abi: ChessABI,
    }

    return {
      readCalls: [
        {
          ...chessContract,
          functionName: 'balances',
          args: [userAddress || '0x0', getDepositTokenAddress()],
        },
        {
          ...chessContract,
          functionName: 'withdrawal',
          args: [userAddress || '0x0'],
        },
      ] as const,
      chessContract,
    }
  }, [address, userAddress])

  // Contract Hook States
  const [tokenData, setTokenData] = useState<ERC20Interface>()

  // Contract Reads
  const { data: _tokenData } = useContractReads({
    contracts: [...readCalls],
    watch: true,
  })

  // Update state when contract data changes
  useEffect(() => {
    if (_tokenData && _tokenData.length > 0 && !_tokenData[0].error) {
      setTokenData({
        balances: formatUnits(_tokenData[0]?.result as bigint, DEPOSIT_TOKEN_DECIMALS),
        withdrawal: _tokenData[1]?.result as bigint,
      })
    }
  }, [_tokenData])

  const { config: depositConfig } = usePrepareContractWrite({
    ...chessContract,
    functionName: 'deposit',
    args: [getDepositTokenAddress(), parseUnits(depositAmt, DEPOSIT_TOKEN_DECIMALS)],
    account: userAddress,
  })

  const deposit = useContractWrite(depositConfig)

  const txData = useWaitForTransaction({
    hash: deposit.data?.hash,
  })

  return {
    address,
    ...tokenData,
    deposit: {
      ...deposit,
      ...txData,
    },
  }
}

export default useChessContract
