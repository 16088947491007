import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 49.194 29.815" {...props}>
      <defs>
        <clipPath id="clip-path-graph">
          <rect
            id="Rectangle_11"
            data-name="Rectangle 11"
            width="49.194"
            height="29.815"
            fill="none"
          />
        </clipPath>
      </defs>
      <path
        id="Path_73"
        data-name="Path 73"
        d="M39.017,38.185H42.4V57.632h-3.38ZM37.745,58.9h5.923V36.913H37.745Z"
        transform="translate(-29.746 -29.09)"
        fill="#fff"
      />
      <path
        id="Path_74"
        data-name="Path 74"
        d="M81.914,1.272h3.38v27.27h-3.38ZM80.643,29.814h5.923V0H80.643Z"
        transform="translate(-63.553 -0.001)"
        fill="#fff"
      />
      <path
        id="Path_75"
        data-name="Path 75"
        d="M124.811,70.109h3.38V82.791h-3.38Zm-1.272,13.954h5.923V68.837h-5.923Z"
        transform="translate(-97.358 -54.249)"
        fill="#fff"
      />
      <path
        id="Path_76"
        data-name="Path 76"
        d="M167.71,38.185h3.38V57.632h-3.38ZM166.438,58.9h5.923V36.913h-5.923Z"
        transform="translate(-131.165 -29.09)"
        fill="#fff"
      />
      <g id="Group_12" data-name="Group 12">
        <g id="Group_11" data-name="Group 11" clipPath="url(#clip-path-graph)">
          <path
            id="Path_77"
            data-name="Path 77"
            d="M14.989,135.719c-14.267,0-14.468-.068-14.554-.1a.636.636,0,0,1,.306-1.23c1.309.1,31.807.039,47.816-.008h0a.636.636,0,0,1,0,1.272c-15.841.047-26.456.064-33.571.064m-14.154-1.3h0"
            transform="translate(0 -105.904)"
            fill="#fff"
          />
        </g>
      </g>
    </Svg>
  )
}

export default Icon
