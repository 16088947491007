import { abi as babydogeABI } from 'abi/babydoge'
import { DEPOSIT_TOKEN_DECIMALS } from 'constants/address'
import { useEffect, useMemo, useState } from 'react'
import { formatUnits, parseUnits } from 'viem'
import { useAccount, useContractReads, useContractWrite, usePrepareContractWrite } from 'wagmi'

interface ERC20Interface {
  decimals: number
  balanceOf?: string
  allowance?: bigint
}

const useERC20 = (
  address?: `0x${string}`,
  recepient: `0x${string}` = '0x0',
  allowanceAmount = '0',
) => {
  // Hook data initialization
  const { address: userAddress } = useAccount()
  const { readCalls, erc20Contract } = useMemo(() => {
    const erc20Contract = {
      address,
      abi: babydogeABI,
    }

    return {
      readCalls: [
        {
          ...erc20Contract,
          functionName: 'decimals',
        },
        {
          ...erc20Contract,
          functionName: 'balanceOf',
          args: [userAddress || '0x0'],
        },
        {
          ...erc20Contract,
          functionName: 'allowance',
          args: [userAddress || '0x0', recepient],
        },
      ] as const,
      erc20Contract,
    }
  }, [address, userAddress, recepient])

  // Contract Hook States
  const [tokenData, setTokenData] = useState<ERC20Interface>()

  // Contract Reads
  const { data: _tokenData } = useContractReads({
    contracts: [...readCalls],
    watch: true,
  })

  // Update state when contract data changes
  useEffect(() => {
    if (_tokenData && _tokenData.length > 0 && !_tokenData[0].error) {
      setTokenData({
        decimals: _tokenData[0]?.result as number,
        balanceOf: formatUnits(
          _tokenData[1]?.result as bigint,
          (_tokenData[0]?.result as number) || DEPOSIT_TOKEN_DECIMALS,
        ),
        allowance: _tokenData[2]?.result as bigint,
      })
    }
  }, [_tokenData])

  // Contract preparation
  const { config: approveConfig } = usePrepareContractWrite({
    ...erc20Contract,
    functionName: 'approve',
    args: [recepient, parseUnits(allowanceAmount, tokenData?.decimals || DEPOSIT_TOKEN_DECIMALS)],
    account: userAddress,
  })

  // Contract writes
  const approve = useContractWrite(approveConfig)

  const isApproved = useMemo(() => {
    return (
      tokenData?.allowance &&
      tokenData?.allowance >= parseUnits(allowanceAmount, tokenData?.decimals)
    )
  }, [allowanceAmount, tokenData?.allowance, tokenData?.decimals])

  const { config: mintConfig } = usePrepareContractWrite({
    ...erc20Contract,
    functionName: 'mint',
    args: [userAddress, 100000000000000000000],
    account: userAddress,
  })

  const mint = useContractWrite(mintConfig)

  return {
    address,
    ...tokenData,
    approve,
    isApproved,
    mint,
  }
}

export default useERC20
