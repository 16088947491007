import { createSlice } from '@reduxjs/toolkit'
import { AppState } from '../types'

const initialState: AppState = {
  isLoading: false,
  isPrivateGame: false,
  bet: '0',
  roomId: '',
  user: null,
  gameId: null,
  gameRoomInfo: null,
  gameTimer: null,
  gameOrientation: null,
  gameSignature: null,
  gameActivePlayers: [],
  gameCapturedPieces: [],
  gameEnded: null,
  messages: {},
  creator: {},
  auth: {
    accessToken: '',
    accessAddress: '',
  },
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),

    setIsPrivateGame: (state, action) => ({
      ...state,
      isPrivateGame: action.payload,
    }),

    setBet(state, action) {
      return {
        ...state,
        bet: action.payload,
      }
    },

    setRoomId(state, action) {
      return {
        ...state,
        roomId: action.payload,
      }
    },

    setUser(state, action) {
      return {
        ...state,
        user: action.payload,
      }
    },

    setGameId(state, action) {
      return {
        ...state,
        gameId: action.payload,
      }
    },

    setGameRoomInfo(state, action) {
      return {
        ...state,
        gameRoomInfo: action.payload,
      }
    },

    setGameTimer(state, action) {
      return {
        ...state,
        gameTimer: action.payload,
      }
    },

    setGameOrientation(state, action) {
      return {
        ...state,
        gameOrientation: action.payload,
      }
    },

    setGameSignature(state, action) {
      return {
        ...state,
        gameSignature: action.payload,
      }
    },

    setGameActivePlayers(state, action) {
      return {
        ...state,
        gameActivePlayers: action.payload,
      }
    },

    setGameCapturedPieces(state, action) {
      return {
        ...state,
        gameCapturedPieces: action.payload,
      }
    },

    setGameEnded(state, action) {
      return {
        ...state,
        gameEnded: action.payload,
      }
    },

    setMessages(state, action) {
      return {
        ...state,
        messages: action.payload,
      }
    },

    setCreator(state, action) {
      return {
        ...state,
        creator: action.payload,
      }
    },

    setAuth(state, action) {
      return {
        ...state,
        auth: action.payload,
      }
    },
  },
})

export const {
  setAuth,
  setLoading,
  setIsPrivateGame,
  setBet,
  setRoomId,
  setUser,
  setGameId,
  setGameRoomInfo,
  setGameTimer,
  setGameOrientation,
  setGameSignature,
  setGameActivePlayers,
  setGameCapturedPieces,
  setGameEnded,
  setMessages,
  setCreator,
} = appSlice.actions

export default appSlice.reducer
