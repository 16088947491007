import { CHAIN_ID, CURRENT_CHAIN } from 'constants/chains'
import { CHAIN_ID_LS_KEY } from 'constants/storageKeys'

const getChainId = () => {
  const supportedChainIds = [CHAIN_ID.BSC, CHAIN_ID.BSC_TESTNET]
  const chainId = localStorage.getItem(CHAIN_ID_LS_KEY)
  const index = supportedChainIds.findIndex((id) => id === Number(chainId))
  return chainId && index > -1 ? Number(chainId) : CURRENT_CHAIN
}

export default getChainId
