import { Config, ConnectorNames } from './types'
import { MetaMaskIcon, WalletConnectIcon } from '../Ethers/icons'

export const connectors: Config[] = [
  {
    title: 'Metamask',
    icon: MetaMaskIcon,
    connectorId: ConnectorNames.Injected,
    href: 'https://metamask.app.link/dapp/chess.babydogeswap.com/',
  },
  {
    title: 'WalletConnect',
    icon: WalletConnectIcon,
    connectorId: ConnectorNames.WalletConnect,
  },
]
export const localStorageKey = 'accountStatus'
