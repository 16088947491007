import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Flex } from '../../components/Flex'
import { ArrowBackIcon, CloseIcon } from '../../components/Svg'
import { IconButton } from '../../components/Button'
import { InjectedProps } from './types'
import { motion } from 'framer-motion'

interface Props extends InjectedProps {
  title: string
  hideCloseButton?: boolean
  onBack?: () => void
  bodyPadding?: string
  width?: string
  children?: ReactNode
}

const StyledModal = styled.div<{ width?: string }>`
  background: #0983dc;
  box-shadow: 0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  width: ${({ width }) => width ?? '100%'};
  z-index: ${({ theme }) => theme.zIndices.modal};
  overflow-y: auto;
  ${({ theme }) => theme.mediaQueries.xs} {
    width: ${({ width }) => width ?? 'auto'};
    min-width: 360px;
    max-width: 100%;
  }

  .closeBtn {
    background: none;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
  }
`

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px;
  font-weight: 700;
`

const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
  font-family: Poppins, serif;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  color: #fff;
  justify-content: center;
`

const Modal: React.FC<Props> = ({
  title,
  onDismiss,
  onBack,
  children,
  width,
  hideCloseButton = false,
  bodyPadding = '24px',
}) => (
  <StyledModal width={width}>
    <ModalHeader>
      <ModalTitle>
        {onBack && (
          <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
            <ArrowBackIcon color="#fff" />
          </IconButton>
        )}
        {title}
      </ModalTitle>
      {!hideCloseButton && (
        <motion.button onClick={onDismiss} className={'closeBtn'} whileHover={{ opacity: 0.65 }}>
          <CloseIcon color="#fff" width={'20px'} />
        </motion.button>
      )}
    </ModalHeader>
    <Flex flexDirection="column" p={bodyPadding}>
      {children}
    </Flex>
  </StyledModal>
)

Modal.defaultProps = {
  hideCloseButton: false,
  onBack: undefined,
  bodyPadding: '24px',
}

export default Modal
