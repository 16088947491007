import styled from 'styled-components'

export const JoinRoomContainer = styled.div`
  background-image: url('/assets/img/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  .joinRoomWrapper {
    min-height: calc(100vh - 53px);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 48px;
  }

  .inviteLink {
    max-width: 1004px;
    width: 100%;
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .roomStatus {
    width: 1004px;
    min-height: 557px;
    border-radius: 22px;
    border: 0.4px solid #6da6ad;
    backdrop-filter: blur(16px);
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    flex-direction: row;

    .leftPart {
      border-right: 1px solid #407e9c;
      flex: 6;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 70px;

      .statusImg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .statusTxt {
        font-family: 'Poppins', serif;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-align: center;
        color: #fff;
      }
    }

    .rightPart {
      flex: 4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      grid-gap: 10px;

      .rightPart-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid #407e9c;
        padding: 27px 33px;
      }

      .rightPart-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 27px 33px;
        justify-content: space-between;
        flex: 1;
      }

      .roomTitle {
        font-size: 14px;
        font-weight: bold;
        color: #0983dc;
        text-transform: uppercase;
      }

      .roomSubtitle {
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        text-transform: uppercase;
      }

      .roomIcon {
        svg {
          height: 58px;
          width: inherit;
        }
      }

      .userStatus {
        width: 442px;
        display: flex;
        flex-direction: column;
        grid-gap: 8px;

        .userStatus-header {
          font-family: Poppins, serif;
          font-weight: bold;
          font-size: 9px;
          letter-spacing: 0.05em;
          text-align: center;
          color: #fff;
          text-transform: uppercase;
          margin-bottom: 4px;
        }

        .userStatus-content {
          height: 73px;
          &.active {
            color: #fff;
            background: #001721;
          }

          padding: 17px 27px;

          font-family: Poppins, serif;
          font-weight: bold;
          font-size: 9px;
          letter-spacing: 0.05em;
          color: #7e7e7e;
          background: rgba(00, 23, 33, 0.37);

          .playerImg {
            margin-right: 12px;
          }

          td {
            padding-top: 17px;
            padding-bottom: 17px;
          }

          td:first-child {
            padding-left: 24px;
            border-radius: 12px 0 0 12px;
          }

          td:last-child {
            padding-right: 24px;
            border-radius: 0 12px 12px 0;
          }

          .joinStatus {
            width: 15px;
            height: 15px;
            background: #4a4a4a;
            border-radius: 100%;
            margin: auto;

            &.active {
              background: #30e230;
            }
          }
          .remainingTime {
            font-family: Poppins, serif;
            font-weight: bold;
            font-size: 18px;
            letter-spacing: -0.02em;
            line-height: 19px;
            text-align: center;
            color: #fff;
          }

          .kickBtn {
            width: 74px;
            height: 28px;
            border-radius: 14px;
            background: #712020;
            border: none;
            text-transform: uppercase;

            font-family: Poppins, serif;
            font-weight: bold;
            font-size: 9px;
            letter-spacing: 0.05em;
            text-align: center;
            color: #fff;
          }
        }
      }

      .readyText {
        font-family: Poppins, serif;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        margin-left: 22px;
      }

      .waitingMsg {
        font-family: Poppins, serif;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 0.05em;
        text-align: center;
        color: #407e9c;
        text-transform: uppercase;
      }

      .btnList {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        grid-gap: 15px;

        .backBtn {
          width: 158px;
          height: 42px;
          border-radius: 20px;
          background: transparent;
          border: 2px solid #fff;

          font-family: Poppins, serif;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.05em;
          text-align: center;
          color: #fff;
        }

        .startBtn {
          width: 158px;
          height: 42px;
          border-radius: 20px;
          background: #fff;
          font-family: Poppins, serif;
          font-weight: bold;
          font-size: 18px;
          letter-spacing: 0.05em;
          border: 1px solid #ffffff;
          text-align: center;
          color: #000;
        }
      }
    }
  }

  @media (max-width: 780px) {
    .roomStatus {
      flex-direction: column;
      width: 100%;

      .leftPart {
        .statusTxt {
          font-size: 21px;
        }
      }

      .rightPart {
        grid-gap: 8px;
        .rightPart-content {
          padding-left: 0;
          padding-right: 0;
        }
        .userStatus {
          width: 100%;
        }

        .roomTitle {
          font-size: 21px;
        }

        .roomSubtitle {
          font-size: 21px;
        }

        svg {
          display: none;
        }

        .btnList {
          flex-direction: column-reverse;
        }

        .rightPart-content {
          grid-gap: 10px;
        }
        .readyText {
          font-size: 14px;
        }
      }
    }
  }
`
