import styled from 'styled-components'

export const GamingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: url('/assets/img/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding-top: 24px;
`
