import 'bootstrap/dist/css/bootstrap.min.css'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import Providers from './Providers'
import Start from './components/start/Start'
import './index.css'

const App = () => {
  return (
    <Providers>
      <BrowserRouter>
        <Start />
      </BrowserRouter>
    </Providers>
  )
}
const container = document.getElementById('root')

createRoot(container).render(<App />)
