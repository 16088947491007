import styled from 'styled-components'
import { useEffect, useState } from 'react'
import useInterval from 'react-useinterval'

const StyledClockBar = styled.div<{
  isRunning: boolean
  orientation: string
  seconds: number
}>`
  width: 169px;
  height: 66px;
  border-radius: 7px;
  background: transparent;
  border: 1px solid #0983dc;
  font-family: 'Poppins', serif;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: #0983dc;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

interface iProps {
  onEnd: () => void
  stopped: boolean
  orientation: string
  startTime: number
  resetTime: boolean
}

export default function ClockBar(props: iProps) {
  // eslint-disable-next-line prefer-const
  let { orientation, stopped, startTime, resetTime, onEnd } = props
  const [seconds, setSeconds] = useState(startTime)

  useEffect(() => {
    setSeconds(startTime)
  }, [resetTime, startTime])

  useInterval(
    () => {
      setSeconds(seconds - 1)
      startTime -= 1
      if (seconds <= 1) {
        onEnd()
      }
    },
    seconds > 0 && !stopped ? 1000 : null,
  )

  const parseMins = (seconds) => {
    if (seconds < 0) seconds = 0
    const mins = Math.floor(seconds / 60)
    const sec = seconds % 60
    return `${mins < 10 ? '0' : ''}${mins} : ${sec < 10 ? '0' : ''}${sec}`
  }
  return (
    <StyledClockBar isRunning={false} orientation={orientation} seconds={seconds}>
      {stopped ? parseMins(startTime) : parseMins(seconds)}
    </StyledClockBar>
  )
}
