import { formatUnits } from 'viem'
import { contracts, tokens } from '@babydogeswap/config'
import { useEffect, useMemo, useState } from 'react'
import useActiveWeb3React from './useActiveWeb3React'
import { abi as RouterABI } from 'abi/babydogeRouter'
import { useContractReads } from 'wagmi'
import { getAddress } from 'helpers/addressHelpers'
import { CURRENT_CHAIN } from 'constants/chains'

interface ERC20Interface {
  price?: string
}

export const useRouterContract = () => {
  const address = getAddress(contracts.BabyDogeRouter)
  const { chainId } = useActiveWeb3React()
  const { babydoge, wbnb, busd } = tokens(chainId ?? CURRENT_CHAIN)
  const path = useMemo(() => [babydoge.address, wbnb.address, busd.address], [babydoge, wbnb, busd])

  const { readCalls } = useMemo(() => {
    const routerContract = {
      address,
      abi: RouterABI,
    }

    return {
      readCalls: [
        {
          ...routerContract,
          functionName: 'getAmountsOut',
          args: ['1000000000', path],
        },
      ] as const,
      routerContract,
    }
  }, [address, path])
  const [tokenData, setTokenData] = useState<ERC20Interface>()

  const { data: _tokenData } = useContractReads({
    contracts: [...readCalls],
    watch: true,
  })
  useEffect(() => {
    if (_tokenData?.length && _tokenData?.length > 0) {
      const price =
        _tokenData[0].status === 'success'
          ? ((_tokenData[0]?.result as bigint[])[2] as bigint) ?? 0
          : 0n
      setTokenData({
        price: formatUnits(BigInt(price), 18),
      })
    }
  }, [_tokenData])
  return {
    ...tokenData,
  }
}
