import styled from 'styled-components'

export const MenuContainer = styled.div`
  background-color: #0983dc;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 53px;
  .menuWrapper {
    width: 1440px;
    padding-left: 30px;
    padding-right: 30px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .leaderBoardBtn {
    color: white;
    font-family: 'Poppins', serif;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;

    width: 120px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 30px;
    background-color: transparent;
  }

  .connectWalletBtn {
    color: #002636;
    font-family: 'Poppins', serif;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;

    width: 120px;
    height: 30px;
    border-radius: 30px;
    background-color: #fff;
    outline: none;
    border: none;
  }

  .walletBtn {
    background-color: #002636;
    font-family: 'Poppins', serif;
    font-size: 10px;
    font-weight: bold;
    width: 120px;
    height: 30px;
    border-radius: 30px;
    color: #fff;
    outline: none;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 4px;
    justify-content: center;
  }
`
