import { Alert } from 'antd'
import 'stylesheets/GameStatus.css'
import cn from 'classnames'

const GameStatus = (props) => {
  return (
    <Alert
      className={cn('gamestatus', {
        ['gamestatus-white']: props.color === 'White',
        ['gamestatus-black']: props.color === 'Black',
      })}
      message={props.message}
      type={props.type}
      showIcon={props.showIcon}
    />
  )
}

export default GameStatus
