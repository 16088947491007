import styled from 'styled-components'

export const LeaderBoardContainer = styled.div`
  background: radial-gradient(#00597e, #002d40);
  min-height: calc(100vh);
  font-family: 'Poppins', serif;

  .leaderBoardTitle {
    font-size: 21px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
  }

  .badgesList {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 15px;
    backdrop-filter: blur(13px);
    background-color: rgba(0, 0, 0, 0.1);
    padding: 30px 80px;

    .badgeItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;

      .badgeTitle {
        font-weight: bold;
        font-size: 11px;
        text-align: left;
        color: #4dcfff;
        margin-top: 15px;
      }

      .badgeValue {
        font-weight: bold;
        font-size: 28px;
        text-align: left;
        color: #fff;
      }
    }
  }

  @media (max-width: 780px) {
    .badgesList {
      flex-direction: column;
    }
  }
`
