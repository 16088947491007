import * as React from 'react'
import { Input } from 'antd'
import 'stylesheets/ChatClient.css'
import { ChatClientWrapper } from './styles'
import { formatBlockchainAddress } from '../../utils'
import { isDesktop } from 'react-device-detect'
import { CloseIcon } from '../Svg'
import moment from 'moment'

const InputGroup = Input.Group

interface ChatClientProps {
  sendMessage: (message: string) => void
  messagesArray: any[]
  isShowChatView: boolean
  hideChatView: () => void
}

interface ChatClientState {
  message: string
  disabled: boolean
}

const buttonStyle = {
  backgroundColor: '#35b1e4 ',
  borderColor: '#35b1e4 ',
}

class ChatClient extends React.Component<ChatClientProps, ChatClientState> {
  messagesEnd: HTMLDivElement | null = null
  chatViewRef: HTMLDivElement | null = null
  state = {
    message: '',
    disabled: false,
  }

  handleMessageSend = () => {
    this.setState({
      message: '',
      disabled: true,
    })
    this.props.sendMessage(this.state.message)
    setTimeout(() => {
      this.setState({
        disabled: false,
      })
    }, 1000)
  }

  onMessageInput = (e) => {
    this.setState({
      message: e.target.value,
    })
  }

  componentWillUpdate() {
    if (this.chatViewRef) {
      this.chatViewRef.scrollTo(0, this.chatViewRef.scrollHeight)
    }
  }

  render() {
    return (
      <>
        <ChatClientWrapper isShowChatView={this.props.isShowChatView}>
          {!isDesktop && (
            <div
              style={{
                position: 'absolute',
                right: '20px',
                top: '15px',
              }}
              onClick={() => this.props.hideChatView()}
            >
              <CloseIcon width={'20px'} color={'#fff'} />
            </div>
          )}
          <div className="chatclient--textarea" ref={(ref) => (this.chatViewRef = ref)}>
            {this.props.messagesArray.map((messageObj, i) => {
              let content
              const chatWrapperClass = ['chatclient-wrapper']
              let from = ''

              if (messageObj.from === 'chatbot') {
                from = 'chatbot'
              } else if (messageObj.from === 'self') {
                from = 'self'
              }
              chatWrapperClass.push(from)

              if (messageObj.from === 'chatbot') {
                let newUser
                let message
                if (messageObj.message !== '') {
                  const messageArray = messageObj.message.split(' ')
                  newUser = messageArray[0]
                  message = messageArray.slice(1).join(' ')
                }

                content = (
                  <div className={chatWrapperClass.join(' ')}>
                    <img src={'/img/player-img.svg'} alt={'player'} width={'20px'} />
                    {messageObj.message === '' ? (
                      <div>
                        <p>
                          Welcome to room&#032;
                          <span>{messageObj.roomId}</span>
                        </p>
                      </div>
                    ) : (
                      <span>
                        {newUser}&#032;<span>{message}</span>
                      </span>
                    )}
                  </div>
                )
              } else {
                content = (
                  <div className={chatWrapperClass.join(' ')}>
                    <div
                      className={
                        messageObj.from === 'self' ? 'chatclient--user self' : 'chatclient--user'
                      }
                    >
                      <span>{formatBlockchainAddress(messageObj.userName, 4, 4)}</span>
                      &nbsp;
                      <span>{moment(messageObj.timestamp).format('HH:mm:ss a')}</span>
                    </div>
                    <div
                      className={
                        messageObj.from === 'self'
                          ? 'chatclient--message self'
                          : 'chatclient--message'
                      }
                    >
                      {messageObj.message}
                    </div>
                  </div>
                )
              }
              return (
                <div key={i}>
                  {content}
                  <div
                    style={{ float: 'left', clear: 'both' }}
                    ref={(node) => {
                      this.messagesEnd = node
                    }}
                  />
                </div>
              )
            })}
          </div>
          <InputGroup compact={true}>
            <Input
              onChange={this.onMessageInput}
              value={this.state.message}
              style={{ width: '313px' }}
              onPressEnter={this.handleMessageSend}
              disabled={this.state.disabled}
            />
            <button
              type="button"
              className="btn"
              style={buttonStyle}
              onClick={this.handleMessageSend}
            >
              <i className="fas fa-paper-plane" aria-disabled={this.state.disabled} />
            </button>
          </InputGroup>
        </ChatClientWrapper>
      </>
    )
  }
}

export default ChatClient
