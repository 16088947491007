export enum ConnectorNames {
  Injected = 'injected', // metamask is just a type of injected connector
  WalletConnect = 'walletConnect',
}

export type Login = (connectorId: ConnectorNames) => void

export interface Config {
  title: string
  icon: any
  connectorId: ConnectorNames
  href?: string
}
