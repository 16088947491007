import { ChainId } from '@babydogeswap/sdk'
import memoize from 'lodash/memoize'
import { configureChains, createConfig } from 'wagmi'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { CHAINS } from './chains'

export const MAIN_NODE_PUBLIC = process.env.REACT_APP_RPC_MAINNET as string
export const TEST_NODE_PUBLIC = process.env.REACT_APP_RPC_TESTNET as string

export const getPublicRpc = (chainId: ChainId, protocol = 'https') => {
  switch (chainId) {
    case ChainId.MAINNET:
      return MAIN_NODE_PUBLIC.replace('https', protocol)
    case ChainId.TESTNET:
      return TEST_NODE_PUBLIC.replace('https', protocol)
    default:
      return MAIN_NODE_PUBLIC.replace('https', protocol)
  }
}

const customPublicProvider = jsonRpcProvider({
  rpc: (chain) => ({
    http: getPublicRpc(chain.id),
    websocket: getPublicRpc(chain.id, 'wss'),
  }),
})

export const { chains, publicClient } = configureChains(CHAINS, [customPublicProvider])

export const injectedConnector = new InjectedConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const walletConnectConnector = new WalletConnectConnector({
  chains: CHAINS.map((chain) => ({
    ...chain,
    rpcUrls: {
      public: {
        ...chain.rpcUrls.public,
        webSocket: [getPublicRpc(chain.id, 'wss')],
      },
      default: {
        ...chain.rpcUrls.public,
        webSocket: [getPublicRpc(chain.id, 'wss')],
      },
    },
  })),
  options: {
    projectId: process.env.REACT_APP_WALLET_CONNECT_ID ?? '',
    showQrModal: true,
    metadata: {
      name: 'Baby Doge Swap',
      description:
        'The meme that started it all is at the heart of the BabyDoge ecosystem. Buy it, earn it, spend it, farm it, or stake it! The first AMM with lowered fees for holders.',
      url: 'https://chess.babydogeswap.com',
      icons: [
        'https://tokens.babydogenews.com/bsc-mainnet/0xc748673057861a797275cd8a068abb95a902e8de/token-logo-large.png',
      ],
    },
  },
})

export const metaMaskConnector = new MetaMaskConnector({
  chains,
  options: {
    shimDisconnect: false,
  },
})

export const wagmiConfig = createConfig({
  autoConnect: false,
  connectors: [injectedConnector, walletConnectConnector, metaMaskConnector],
  publicClient,
})

export const CHAIN_IDS = CHAINS.map((c) => c.id)

export const isChainSupported = memoize((chainId: number) => CHAIN_IDS.includes(chainId))
export const isChainTestnet = memoize(
  (chainId: number) => CHAINS.find((c) => c.id === chainId)?.testnet,
)
