import styled from 'styled-components'
import { space } from 'styled-system'
import { TextProps } from './types'

const getFontSize = ({ fontSize, small }: TextProps) => {
  return small ? '14px' : fontSize || '16px'
}

const Text = styled.div<TextProps>`
  color: #000;
  font-size: ${getFontSize};
  font-weight: ${({ bold, fontWeight = 200 }) => (bold ? 700 : fontWeight)};
  line-height: 1.5;
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}
  text-align: ${({ textAlign }) => textAlign};
  ${space}
`

Text.defaultProps = {
  color: 'text',
  small: false,
}

export default Text
