import styled from 'styled-components'

export const GameBoardContainer = styled.div`
  .gameRightPanel {
    border-radius: 22px;
    background: #001721;
    padding: 24px 24px 41px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .capturePiece {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-color: #014d84;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .gameRightPanel-title {
      font-family: 'Poppins', serif;
      font-weight: bold;
      font-size: 11px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #fff;
    }

    .playerAddress {
      font-family: 'Poppins', serif;
      font-weight: bold;
      font-size: 8px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #b9b9b9;
    }

    .remainingTime {
      font-family: 'Poppins', serif;
      font-weight: bold;
      font-size: 20px;
      letter-spacing: 0.05em;
      text-align: center;
      color: #fff;
    }

    .messengerBtn {
      display: none;
    }
  }

  @media (max-width: 780px) {
    .gameRightPanel {
      align-items: flex-start;
      flex-direction: row;
      .messengerBtn {
        display: block;
        position: absolute;
        right: -18px;
        top: 50px;
        cursor: pointer;
        .red-dot {
          width: 20px;
          height: 20px;
          background: #bf4040;
          border-radius: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
`
