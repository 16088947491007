import API from 'api/api'
import { DEPOSIT_TOKEN_DECIMALS } from 'constants/address'
import { getBabydogeAddress } from 'helpers/addressHelpers'
import useActiveWeb3React from 'hook/useActiveWeb3React'
import useERC20 from 'hook/useERC20'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { nFormatter } from 'utils'
import { formatUnits } from 'viem'
import useAuth from '../../hook/useAuth'
import { setLoading } from '../../state/app'
import Menu from '../Menu/Menu'
import BalanceIcon from '../Svg/Icons/BalanceIcon'
import GraphIcon from '../Svg/Icons/GraphIcon'
import LostIcon from '../Svg/Icons/LostIcon'
import WonIcon from '../Svg/Icons/WonIcon'
import BadgeBoardComponent from './badgeBoard'
import { LeaderBoardContainer } from './styles'
import { useNavigate } from 'react-router-dom'

const LeaderBoard = () => {
  const { account, isAttemptingConnect } = useActiveWeb3React()
  const [winCount, setWinCount] = useState(0)
  const [loseCount, setLostCount] = useState(0)
  const [winAmount, setWinAmount] = useState(0)
  const [loseAmount, setLostAmount] = useState(0)
  const dispatch = useDispatch()
  const { login, logout } = useAuth()
  const { balanceOf } = useERC20(getBabydogeAddress())
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      if (account) {
        dispatch(setLoading(true))
        const res = await API.getStats(account)

        if (res.data.userData) {
          setWinAmount(Number(formatUnits(res.data.userData.winAmount, DEPOSIT_TOKEN_DECIMALS)))
          setWinCount(res.data.userData.winCount)
          setLostAmount(Number(formatUnits(res.data.userData.loseAmount, DEPOSIT_TOKEN_DECIMALS)))
          setLostCount(res.data.userData.loseCount)
        }
        dispatch(setLoading(false))
      }
    }
    if (!account && !isAttemptingConnect) {
      navigate('/')
    }
    fetchData()
  }, [account, dispatch, isAttemptingConnect, navigate])
  return (
    <LeaderBoardContainer>
      <Menu account={account || undefined} login={login} logout={logout} />
      <div className="container leaderboard mt-4">
        <div className="leader-board-container">
          <h1 className="leaderBoardTitle">My Stats</h1>
          <div className="badgesList">
            <BadgeBoardComponent
              icon={<BalanceIcon width={'40px'} />}
              heading="Your Baby Doge Balance:"
              value={`${nFormatter(balanceOf, 1)}`}
            />
            <BadgeBoardComponent
              icon={<WonIcon width={'40px'} />}
              heading="Your Total Games Won:"
              value={winCount}
            />
            <BadgeBoardComponent
              icon={<LostIcon width={'68px'} />}
              heading="Your Total Games Lost:"
              value={loseCount}
            />
            <BadgeBoardComponent
              icon={<GraphIcon width={'50px'} />}
              heading="Your Total Wins/Lost:"
              value={`${nFormatter(Number(winAmount), 1)} / ${nFormatter(Number(loseAmount), 1)}`}
            />
          </div>
        </div>
      </div>
      <div className="footer-links">
        <a
          href="https://docs.babydogeswap.com/babydoge-docs/products/chess/welcome-to-babydoge-chess/testnet-video-tutorial"
          target={'_blank'}
          className="reportBug"
        >
          Video Tutorial
        </a>
        <a
          href="https://docs.babydogeswap.com/babydoge-docs/products/chess/welcome-to-babydoge-chess"
          target={'_blank'}
          className="reportBug"
        >
          Documentation
        </a>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdlvIjYy1_GC8agnzVQfyacHCC1RUpLrmeF8eGBUvyP1R1awA/viewform?usp=sf_link"
          target={'_blank'}
          className="reportBug"
        >
          Report a bug
        </a>
      </div>
    </LeaderBoardContainer>
  )
}

const LeaderBoardHome = () => {
  const { account, isAttemptingConnect } = useActiveWeb3React()
  const navigate = useNavigate()
  if (!account && !isAttemptingConnect) {
    navigate('/')
  }
  return <LeaderBoard />
}
export default LeaderBoardHome
