import styled from 'styled-components'

export const RoomItemWrapper = styled.div`
  width: 238px;
  height: 287px;
  border-radius: 22px;
  border: 0.4px solid #6da6ad;
  backdrop-filter: blur(16px);
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 21px 18px;

  .title {
    font-size: 14px;
    font-weight: bold;
    color: #0983dc;
    text-transform: uppercase;
  }

  .subTitle {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
  }

  .joinBtn {
    width: 121px;
    height: 32px;
    background-color: white;
    border-radius: 16px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #000;
    border: 1px solid #fff;
  }

  .roomStatus {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    width: 100%;

    .betAmt {
      font-weight: bold;
      font-size: 14px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #ffce00;

      .usdAmt {
        font-size: 10px;
        font-weight: 400;
        color: #fff;
      }
    }
    .free-bet {
      color: #10f857;
    }
    .custom-bet {
      color: #bf4040;
    }

    .waitingStatus {
      font-weight: bold;
      font-size: 9px;
      letter-spacing: 0.05em;
      text-align: right;
      color: #fff;
    }
  }
`
