export const MetaMaskIcon = ({ name }) => {
  return (
    <div className="wallet-icon-wrapper">
      <img src={'/img/wallets/metamask-logo.svg'} alt={'metamask'} />
      <span className="wallet-name"> {name} </span>
    </div>
  )
}

export const WalletConnectIcon = ({ name }) => {
  return (
    <div className="wallet-icon-wrapper">
      <img src={'/img/wallets/walletconnect.svg'} alt={'walletconnect'} />
      <span className="wallet-name"> {name} </span>
    </div>
  )
}
