import { contracts } from '@babydogeswap/config'
import { DEPOSIT_TOKEN_ADDRESS } from './../constants/address'
import { BABYDOGE_CONTRACT_ADDRESS, CHESS_CONTRACT_ADDRESS } from 'constants/address'
import { CURRENT_CHAIN } from 'constants/chains'
import { Address } from 'constants/types'
import getChainId from 'utils/getChainId'

export const getAddress = (address: Address): `0x${string}` => {
  const chainId = getChainId()
  return address[chainId] ? address[chainId] : address[CURRENT_CHAIN]
}
export const getBabyDogeRouterAddress = () => {
  return getAddress(contracts.BabyDogeRouter)
}

export const getBabydogeAddress = () => {
  return getAddress(BABYDOGE_CONTRACT_ADDRESS)
}

export const getChessAddress = (): `0x${string}` => {
  return getAddress(CHESS_CONTRACT_ADDRESS)
}

export const getDepositTokenAddress = () => {
  return getAddress(DEPOSIT_TOKEN_ADDRESS)
}
