import ResignModal from './ResignModal'
import { useModal } from '../../components/Modal'

interface ReturnType {
  onPresentResignModal: () => void
}

const useResignModal = (onCallback?: () => void): ReturnType => {
  const [onPresentResignModal] = useModal(<ResignModal onCallback={onCallback} />)
  return { onPresentResignModal }
}

export default useResignModal
