import axios from 'axios'

export default class API {
  static async onWithdraw(withdrawal, sig) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}withdraw`, {
      withdrawal,
      sig,
    })
  }

  static async getRoomCountByAddress(address) {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}gameRoomCountByAddress`, {
      params: {
        address,
      },
    })
  }

  static async getStats(address) {
    return await axios.get(`${process.env.REACT_APP_SERVER_URL}leaderboard`, {
      params: {
        address,
      },
    })
  }

  static async getUserToken(address) {
    return await axios.post(`${process.env.REACT_APP_SERVER_URL}token`, {
      userAddress: address,
    })
  }
}
