import { useModal } from '../../components/Modal'
import { RequestDrawModal } from './index'

interface ReturnType {
  onPresentRequestDrawModal: () => void
}

const useRequestDrawModal = (onCallback?: () => void): ReturnType => {
  const [onPresentRequestDrawModal] = useModal(<RequestDrawModal onCallback={onCallback} />)
  return { onPresentRequestDrawModal }
}

export default useRequestDrawModal
