import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { load, save } from 'redux-localstorage-simple'
import appReducer from './app'
import toastsReducer from './toasts'

/*

add "logStateMiddleware" to middleware array, after save(), to log state changes

const logStateMiddleware = (storeAPI) => (next) => (action) => {
  const result = next(action); // Pass the action to the next middleware or reducer
  console.log(storeAPI.getState()); // Log the state
  return result;
};

*/

const PERSISTED_KEYS: string[] = ['app', 'user', 'transactions']
const store = configureStore({
  reducer: {
    app: appReducer,
    toasts: toastsReducer,
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

export default store
