import { Square } from 'chess.js'
import { useEffect, useRef, useState } from 'react'
import { Chessboard } from 'react-chessboard'
import { useSelector } from 'react-redux'
import { State } from 'state/types'
import 'stylesheets/Chessboard.css'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Chess = require('chess.js')

interface iProps {
  boardWidth: number
  fen: string
  onMoveCallback?: (chessObj) => void
  onCapturedPieces?: (capturedPieces) => void
}
export default function ChessBoard(props: iProps) {
  const app = useSelector((state: State) => state.app)
  const { boardWidth, onMoveCallback, fen, onCapturedPieces } = props
  const chessboardRef = useRef<any>()
  const [game, setGame] = useState(Chess())
  const [moveFrom, setMoveFrom] = useState('')
  const [optionSquares, setOptionSquares] = useState({})

  useEffect(() => {
    if (game.fen() !== fen) {
      game.load(fen)
    }
  }, [fen, game])

  function getMoveOptions(square) {
    const moves = game.moves({
      square,
      verbose: true,
    })
    if (moves.length === 0) {
      return
    }

    const newSquares = {}
    moves.map((move) => {
      newSquares[move.to] = {
        background:
          game.get(move.to) && game.get(move.to).color !== game.get(square).color
            ? 'radial-gradient(circle, rgba(0,0,0,.1) 85%, transparent 85%)'
            : 'radial-gradient(circle, rgba(0,0,0,.1) 25%, transparent 25%)',
        borderRadius: '50%',
      }
      return !!move
    })
    newSquares[square] = {
      background: 'rgba(255, 255, 0, 0.4)',
    }
    setOptionSquares(newSquares)
  }

  function resetFirstMove(square) {
    setMoveFrom(square)
    getMoveOptions(square)
  }

  function onSquareClick(square) {
    if (game.turn() !== app.gameOrientation) {
      return
    }

    // from square
    if (!moveFrom) {
      resetFirstMove(square)
      return
    }

    // attempt to make move
    const gameCopy = { ...game }
    const move = gameCopy.move({
      from: moveFrom as Square,
      to: square,
      promotion: 'q', // always promote to a queen for example simplicity
    })
    setGame(gameCopy)

    // if invalid, setMoveFrom and getMoveOptions
    if (move === null) {
      setMoveFrom('')
      setOptionSquares({})
      return
    }

    if (move?.captured) {
      const newCapturedPieces: any[] = [...app.gameCapturedPieces, { ...move }]
      onCapturedPieces(newCapturedPieces)
    }

    onMoveCallback(gameCopy)
    setMoveFrom('')
    setOptionSquares({})
  }

  function onDragBegin(piece, sourceSquare) {
    resetFirstMove(sourceSquare)
  }

  function onDrop(sourceSquare, targetSquare) {
    const gameCopy = { ...game }
    const move = gameCopy.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: 'q', // always promote to a queen for example simplicity
    })
    if (move) {
      setGame(gameCopy)
      onMoveCallback(gameCopy)
      if (move.captured) {
        const newCapturedPieces: any[] = [...app.gameCapturedPieces]
        newCapturedPieces.push({ ...move })
        onCapturedPieces(newCapturedPieces)
      }
    }
    setOptionSquares({})
    return !!move
  }

  return (
    <div>
      <Chessboard
        animationDuration={200}
        boardWidth={boardWidth}
        position={props.fen}
        onSquareClick={onSquareClick}
        customSquareStyles={{
          ...optionSquares,
        }}
        onPieceDragBegin={onDragBegin}
        onPieceDrop={onDrop}
        ref={chessboardRef}
        boardOrientation={app.gameOrientation === 'w' ? 'white' : 'black'}
        customDarkSquareStyle={{
          backgroundColor: '#014d84',
        }}
        customLightSquareStyle={{
          backgroundColor: '#0a88e5',
        }}
        snapToCursor={true}
        arePiecesDraggable={game.turn() === app.gameOrientation}
        customPieces={{
          wP: () => (
            <img src={'/assets/img/chessPieces/wP.png'} alt={'wP'} className={'pieceImg'} />
          ),
          wB: () => (
            <img src={'/assets/img/chessPieces/wB.png'} alt={'wB'} className={'pieceImg'} />
          ),
          wN: () => (
            <img src={'/assets/img/chessPieces/wN.png'} alt={'wN'} className={'pieceImg'} />
          ),
          wR: () => (
            <img src={'/assets/img/chessPieces/wR.png'} alt={'wR'} className={'pieceImg'} />
          ),
          wQ: () => (
            <img src={'/assets/img/chessPieces/wQ.png'} alt={'wQ'} className={'pieceImg'} />
          ),
          wK: () => (
            <img src={'/assets/img/chessPieces/wK.png'} alt={'wK'} className={'pieceImg'} />
          ),
          bP: () => (
            <img src={'/assets/img/chessPieces/bP.png'} alt={'bP'} className={'pieceImg'} />
          ),
          bB: () => (
            <img src={'/assets/img/chessPieces/bB.png'} alt={'bB'} className={'pieceImg'} />
          ),
          bN: () => (
            <img src={'/assets/img/chessPieces/bN.png'} alt={'bN'} className={'pieceImg'} />
          ),
          bR: () => (
            <img src={'/assets/img/chessPieces/bR.png'} alt={'bR'} className={'pieceImg'} />
          ),
          bQ: () => (
            <img src={'/assets/img/chessPieces/bQ.png'} alt={'bQ'} className={'pieceImg'} />
          ),
          bK: () => (
            <img src={'/assets/img/chessPieces/bK.png'} alt={'bK'} className={'pieceImg'} />
          ),
        }}
      />
    </div>
  )
}
