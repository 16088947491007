import { DefaultTheme } from 'styled-components'
import { dark as darkButton } from '../components/Button/theme'
import { dark as darkModal } from '../components/Modal/theme'
import base from './base'
import { darkColors } from './colors'

const darkTheme: DefaultTheme = {
  ...base,
  isDark: true,
  button: darkButton,
  modal: darkModal,
  colors: darkColors,
}

export default darkTheme
