import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 39.942 38.272" {...props}>
      <defs>
        <clipPath id="clip-path-balance">
          <rect
            id="Rectangle_8"
            data-name="Rectangle 8"
            width="39.942"
            height="38.272"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_5" data-name="Group 5" clipPath="url(#clip-path-balance)">
        <path
          id="Path_47"
          data-name="Path 47"
          d="M113.993,4.4a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714s.982-1.322,3.282-1.714a48.864,48.864,0,0,1,13.758,0c2.3.392,3.282.9,3.282,1.714s-.982,1.322-3.282,1.714a43.1,43.1,0,0,1-6.879.487m-8.884-2.2c.785.424,3.79,1.067,8.884,1.067s8.1-.643,8.884-1.067c-.785-.424-3.79-1.067-8.884-1.067s-8.1.643-8.884,1.067m17.947.132h0Z"
          transform="translate(-84.211 0)"
          fill="#fff"
        />
        <path
          id="Path_48"
          data-name="Path 48"
          d="M113.993,45.585a43.122,43.122,0,0,1-6.879-.486c-2.3-.392-3.282-.9-3.282-1.714V9.764a.567.567,0,1,1,1.134,0V43.29c.472.414,3.536,1.162,9.027,1.162s8.555-.747,9.027-1.162V38.838a.567.567,0,1,1,1.134,0v4.546c0,.81-.982,1.322-3.282,1.714a43.123,43.123,0,0,1-6.879.486"
          transform="translate(-84.211 -7.459)"
          fill="#fff"
        />
        <path
          id="Path_49"
          data-name="Path 49"
          d="M113.993,35.576a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.507,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.322-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-84.211 -26.608)"
          fill="#fff"
        />
        <path
          id="Path_50"
          data-name="Path 50"
          d="M113.993,60.849a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.507,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.322-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-84.211 -47.105)"
          fill="#fff"
        />
        <path
          id="Path_51"
          data-name="Path 51"
          d="M113.993,86.122a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.508,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.323-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-84.211 -67.603)"
          fill="#fff"
        />
        <path
          id="Path_52"
          data-name="Path 52"
          d="M113.993,111.395a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.507,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.322-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-84.211 -88.1)"
          fill="#fff"
        />
        <path
          id="Path_53"
          data-name="Path 53"
          d="M113.993,136.669a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.507,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.322-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-84.211 -108.598)"
          fill="#fff"
        />
        <path
          id="Path_54"
          data-name="Path 54"
          d="M113.993,161.941a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.508,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.323-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-84.211 -129.094)"
          fill="#fff"
        />
        <path
          id="Path_55"
          data-name="Path 55"
          d="M10.24,57.164a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714s.982-1.322,3.282-1.714a43.1,43.1,0,0,1,6.879-.487,43.1,43.1,0,0,1,6.879.487c2.3.392,3.282.9,3.282,1.714s-.982,1.322-3.282,1.714a43.1,43.1,0,0,1-6.879.487m-8.884-2.2c.785.424,3.79,1.067,8.884,1.067s8.1-.643,8.884-1.067c-.785-.424-3.79-1.067-8.884-1.067s-8.1.643-8.884,1.067m17.947.132h0Z"
          transform="translate(-0.064 -42.792)"
          fill="#fff"
        />
        <path
          id="Path_56"
          data-name="Path 56"
          d="M10.24,88.523a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714V81.776a.567.567,0,1,1,1.134,0v4.451c.472.414,3.536,1.162,9.027,1.162s8.555-.747,9.027-1.162v-23.7a.567.567,0,1,1,1.134,0v23.8c0,.81-.982,1.322-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-0.064 -50.251)"
          fill="#fff"
        />
        <path
          id="Path_57"
          data-name="Path 57"
          d="M10.24,88.338a43.123,43.123,0,0,1-6.879-.486c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.507,1.17,9.036,1.17s8.6-.757,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.323-3.282,1.714a43.121,43.121,0,0,1-6.879.486"
          transform="translate(-0.064 -69.401)"
          fill="#fff"
        />
        <path
          id="Path_58"
          data-name="Path 58"
          d="M10.24,113.612a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.508,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.323-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-0.064 -89.898)"
          fill="#fff"
        />
        <path
          id="Path_59"
          data-name="Path 59"
          d="M10.24,138.885a43.1,43.1,0,0,1-6.879-.487c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.507,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.322-3.282,1.714a43.1,43.1,0,0,1-6.879.487"
          transform="translate(-0.064 -110.395)"
          fill="#fff"
        />
        <path
          id="Path_60"
          data-name="Path 60"
          d="M10.24,164.158a43.118,43.118,0,0,1-6.879-.486c-2.3-.392-3.282-.9-3.282-1.714a.567.567,0,0,1,1.125-.1c.439.412,3.507,1.17,9.036,1.17s8.6-.758,9.036-1.17a.567.567,0,0,1,1.125.1c0,.81-.982,1.323-3.282,1.714a43.115,43.115,0,0,1-6.879.486"
          transform="translate(-0.064 -130.892)"
          fill="#fff"
        />
        <path
          id="Path_61"
          data-name="Path 61"
          d="M205.935,111.324a.567.567,0,0,1-.567-.567V98.44a.567.567,0,0,1,1.134,0v12.317a.567.567,0,0,1-.567.567"
          transform="translate(-166.559 -79.378)"
          fill="#fff"
        />
        <path
          id="Path_62"
          data-name="Path 62"
          d="M205.935,21.893a.567.567,0,0,1-.567-.567V9.763a.567.567,0,1,1,1.134,0V21.326a.567.567,0,0,1-.567.567"
          transform="translate(-166.559 -7.458)"
          fill="#fff"
        />
        <path
          id="Path_63"
          data-name="Path 63"
          d="M.567,77.071A.567.567,0,0,1,0,76.5V59.977a.567.567,0,1,1,1.134,0V76.5a.567.567,0,0,1-.567.567"
          transform="translate(0 -48.183)"
          fill="#fff"
        />
      </g>
    </Svg>
  )
}

export default Icon
