import { Modal } from '../../components/Modal'
import { Flex } from '../../components/Flex'
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface Props {
  onAcceptDraw?: () => void
  onRejectDraw?: () => void
  onDismiss?: () => void
}

const ConfirmDrawWrapper = styled.div`
  .desc {
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #fff;
  }
  .acceptBtn {
    width: 102px;
    height: 27px;
    border-radius: 13.5px;
    background: #bf4040;
    border: 1px solid #bf4040;
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
  }

  .declineBtn {
    width: 102px;
    height: 27px;
    border-radius: 13.5px;
    background: transparent;
    border: 1px solid #fff;
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
  }
`
export default function ConfirmDrawModal(props: Props) {
  const { onDismiss, onAcceptDraw, onRejectDraw } = props
  return (
    <Modal onDismiss={onDismiss} title={'Confirm Draw'}>
      <ConfirmDrawWrapper>
        <div className={'desc'}>Other player is requesting to draw this game!</div>
        <div className={'desc'}>Are you confirming to draw this game?</div>

        <Flex justifyContent={'space-between'} style={{ gridGap: '30px', marginTop: '24px' }}>
          <motion.button
            className={'declineBtn'}
            onClick={() => {
              onRejectDraw && onRejectDraw()
              onDismiss && onDismiss()
            }}
          >
            Decline
          </motion.button>
          <motion.button
            className={'acceptBtn'}
            onClick={() => {
              onAcceptDraw && onAcceptDraw()
              onDismiss && onDismiss()
            }}
          >
            Accept
          </motion.button>
        </Flex>
      </ConfirmDrawWrapper>
    </Modal>
  )
}
