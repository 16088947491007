import API from 'api/api'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { Socket, io } from 'socket.io-client'
import { useDispatch, useSelector } from 'react-redux'
import { setAuth } from 'state/app'
import { State } from 'state/types'

interface SocketContextProps {
  SocketIo: Socket | null
}
const SocketContext = createContext<SocketContextProps>({
  SocketIo: null,
})

const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const { address } = useAccount()
  const { auth } = useSelector((state: State) => state.app)
  const { accessAddress, accessToken } = auth ?? {}
  const dispatch = useDispatch()
  const [SocketIo, setSocketIo] = useState<Socket | null>(null)

  const needsAuth =
    Boolean(address) &&
    (!accessAddress || !accessToken || accessAddress !== address || !SocketIo?.connected)

  const onConnect = useCallback(
    (socket = SocketIo) =>
      () => {
        setSocketIo(socket)
      },
    [SocketIo],
  )

  const initSocket = useCallback(() => {
    const socket = io(process.env.REACT_APP_SERVER_URL, {
      query: { token: accessToken },
      reconnectionDelay: 200,
      reconnectionDelayMax: 1000,
    })
    socket.on('connect', onConnect(socket))
  }, [accessToken, onConnect])

  const handleSocketHandshake = useCallback(async () => {
    try {
      if (auth?.accessAddress !== address || !auth?.accessToken || !SocketIo) {
        const { data } = await API.getUserToken(address)
        const { token, success } = data
        if (success) {
          dispatch(
            setAuth({
              accessAddress: address,
              accessToken: token,
            }),
          )
        }
      }

      initSocket()
    } catch (e) {
      dispatch(
        setAuth({
          accessAddress: '',
          accessToken: '',
        }),
      )
      setSocketIo(null)
    }
  }, [SocketIo, address, auth?.accessAddress, auth?.accessToken, dispatch, initSocket])

  useEffect(() => {
    if (needsAuth) {
      handleSocketHandshake()
    }

    return () => {
      SocketIo?.off('connect', onConnect())
    }
  }, [handleSocketHandshake, needsAuth, SocketIo, onConnect])

  return <SocketContext.Provider value={{ SocketIo }}>{children}</SocketContext.Provider>
}

function useSocket() {
  return useContext(SocketContext)
}

export { SocketProvider, useSocket }
