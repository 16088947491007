import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 40.225 36.421" {...props}>
      <defs>
        <clipPath id="clip-path-won">
          <rect
            id="Rectangle_10"
            data-name="Rectangle 10"
            width="40.225"
            height="36.421"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_9" data-name="Group 9" clipPath="url(#clip-path-won)">
        <path
          id="Path_66"
          data-name="Path 66"
          d="M43.974,30.609a1.688,1.688,0,0,1-.787-.2L35.154,26.19l-8.033,4.223a1.687,1.687,0,0,1-2.448-1.779l1.534-8.945-6.5-6.334a1.687,1.687,0,0,1,.935-2.878l8.981-1.305,4.016-8.139a1.688,1.688,0,0,1,3.026,0l4.016,8.139,8.981,1.305a1.687,1.687,0,0,1,.935,2.878l-6.5,6.334,1.534,8.945a1.69,1.69,0,0,1-1.661,1.974m-8.82-5.856,8.625,4.534a.416.416,0,0,0,.6-.438l-1.647-9.6,6.978-6.8a.416.416,0,0,0-.23-.709l-9.643-1.4L35.527,1.6a.416.416,0,0,0-.746,0l-4.312,8.738-9.643,1.4a.416.416,0,0,0-.231.709l6.977,6.8-1.647,9.6a.416.416,0,0,0,.6.438Z"
          transform="translate(-15.129 -0.073)"
          fill="#fff"
        />
        <path
          id="Path_67"
          data-name="Path 67"
          d="M.636,97.823a.636.636,0,0,1-.181-1.245L7.787,94.4a.636.636,0,1,1,.362,1.219L.817,97.8a.631.631,0,0,1-.181.026"
          transform="translate(0 -74.374)"
          fill="#fff"
        />
        <path
          id="Path_68"
          data-name="Path 68"
          d="M38.476,7.247a.635.635,0,0,1-.5-.239L33.2,1.033A.636.636,0,1,1,34.2.239l4.775,5.975a.636.636,0,0,1-.5,1.033"
          transform="translate(-26.058 0)"
          fill="#fff"
        />
        <path
          id="Path_69"
          data-name="Path 69"
          d="M127.763,7.247a.636.636,0,0,1-.5-1.033L132.042.239a.636.636,0,0,1,.994.794L128.26,7.008a.635.635,0,0,1-.5.239"
          transform="translate(-100.186 0)"
          fill="#fff"
        />
        <path
          id="Path_70"
          data-name="Path 70"
          d="M157.173,97.823a.631.631,0,0,1-.181-.026L149.66,95.62a.636.636,0,1,1,.362-1.219l7.332,2.177a.636.636,0,0,1-.181,1.245"
          transform="translate(-117.584 -74.374)"
          fill="#fff"
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="M93.218,141.993a.636.636,0,0,1-.636-.636V134.6a.636.636,0,0,1,1.272,0v6.76a.636.636,0,0,1-.636.636"
          transform="translate(-72.961 -105.572)"
          fill="#fff"
        />
        <path
          id="Path_72"
          data-name="Path 72"
          d="M69.065,77.971a6.845,6.845,0,0,1-6.838-6.838.636.636,0,1,1,1.272,0,5.566,5.566,0,0,0,11.132,0,.636.636,0,1,1,1.272,0,6.845,6.845,0,0,1-6.838,6.838"
          transform="translate(-49.039 -55.558)"
          fill="#fff"
        />
      </g>
    </Svg>
  )
}

export default Icon
