import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { State } from 'state/types'
import { useSocket } from 'contexts/SocketContext'
import { useEffect } from 'react'
import { setLoading } from 'state/app'

export default function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const app = useSelector((state: State) => state.app)
    const dispatch = useDispatch()
    const { SocketIo } = useSocket()

    useEffect(() => {
      if (!SocketIo) {
        dispatch(setLoading(true))
      }

      if (app.isLoading && SocketIo) {
        dispatch(setLoading(false))
      }
    }, [SocketIo, app.isLoading, dispatch])

    return SocketIo ? (
      <Component
        {...props}
        SocketIo={SocketIo}
        state={app}
        dispatch={dispatch}
        router={{ location, navigate, params }}
      />
    ) : (
      <></>
    )
  }

  return ComponentWithRouterProp
}
