import { localStorageKey } from 'components/WalletModal/config'
import { CURRENT_CHAIN } from 'constants/chains'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ConnectorNotFoundError, useConnect, useDisconnect, useSwitchNetwork } from 'wagmi'
import { ConnectorNames } from '../components/WalletModal/types'
import { clearUserStates } from '../utils/clearUserStates'
import { useToast } from './useToast'
import useActiveWeb3React from './useActiveWeb3React'

const useAuth = () => {
  const { switchNetworkAsync, switchNetwork } = useSwitchNetwork({ chainId: CURRENT_CHAIN })
  const { chainId } = useActiveWeb3React()
  const [isLogging, setIsLogging] = useState(false)
  const dispatch = useDispatch()
  const { connectAsync, connectors } = useConnect()
  const { disconnectAsync } = useDisconnect()
  const { toastError } = useToast()

  const login = useCallback(
    async (connectorID: ConnectorNames) => {
      setIsLogging(true)
      if (chainId !== CURRENT_CHAIN && switchNetworkAsync) {
        try {
          await switchNetworkAsync()
          setIsLogging(false)
          return
        } catch (err) {
          console.log('Error on switching network:', err)
        }
      }
      const findConnector = connectors.find((c) => c.id === connectorID)
      try {
        const connected = await connectAsync({
          connector: findConnector,
          chainId: CURRENT_CHAIN,
        })
        setIsLogging(false)
        if (connected.chain.unsupported) {
          try {
            switchNetwork()
          } catch {}
        }
      } catch (error) {
        setIsLogging(false)
        console.error(error)
        window?.localStorage?.removeItem(localStorageKey)
        if (error instanceof ConnectorNotFoundError) {
          toastError('Provider Error', 'No provider was found')
          return
        }
        if (error instanceof Error) {
          toastError(error.message, 'Please authorize to access your account')
        }
      }
    },
    [connectors, connectAsync, toastError, switchNetwork, switchNetworkAsync, chainId],
  )

  const logout = useCallback(async () => {
    try {
      await disconnectAsync()
    } catch (error) {
      console.error(error)
    } finally {
      clearUserStates(dispatch, true)
    }
  }, [disconnectAsync, dispatch])

  return { login, logout, isLogging }
}

export default useAuth
