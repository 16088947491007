import React, { ReactNode } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import store from 'state'
import ModalProvider from './components/Modal/ModalContext'
import { RefreshContextProvider } from './contexts/RefreshContext'
import { ThemeContextProvider } from './contexts/ThemeContex'
import { WagmiClientProvider } from 'utils/wagmi/provider'
import { SocketProvider } from 'contexts/SocketContext'

const Providers: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeContextProvider>
        <WagmiClientProvider>
          <RefreshContextProvider>
            <SocketProvider>
              <ModalProvider>
                {children}
                <ToastContainer />
              </ModalProvider>
            </SocketProvider>
          </RefreshContextProvider>
        </WagmiClientProvider>
      </ThemeContextProvider>
    </Provider>
  )
}

export default Providers
