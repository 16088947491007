import React from 'react'
import StyledButton from './StyledButton'
import { ButtonProps, variants, sizes } from './types'
import getExternalLinkProps from '../../utils/getExternalLinkProps'

const Button: React.FC<ButtonProps> = ({
  startIcon,
  endIcon,
  children,
  external,
  isLoading,
  disabled,
  fontFamily,
  color,
  ...props
}) => {
  const internalProps = external ? getExternalLinkProps() : {}
  const isDisabled = isLoading || disabled

  return (
    <StyledButton
      {...internalProps}
      {...props}
      isLoading={isLoading}
      disabled={isDisabled}
      fontFamily={fontFamily}
      color={color}
    >
      {React.isValidElement(startIcon) && React.cloneElement(startIcon)}
      {children}
      {React.isValidElement(endIcon) && React.cloneElement(endIcon)}
    </StyledButton>
  )
}

Button.defaultProps = {
  variant: variants.PRIMARY,
  size: sizes.MD,
  external: false,
  isLoading: false,
  disabled: false,
}

export default Button
