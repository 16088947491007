import { signTypedData } from '@wagmi/core'
import { Checkbox } from 'antd'
import { motion } from 'framer-motion'
import { getDepositTokenAddress } from 'helpers/addressHelpers'
import useActiveWeb3React from 'hook/useActiveWeb3React'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useNavigate } from 'react-router-dom'
import Menu from '../../components/Menu/Menu'
import PlayerStatus, { PlayerStatusType } from '../../components/PlayerStatus/PlayerStatus'
import { ChessLogo } from '../../components/Svg'
import { DEPOSIT_TOKEN_DECIMALS } from '../../constants/address'
import useAuth from '../../hook/useAuth'
import { useToast } from '../../hook/useToast'
import { JoinRoomContainer } from './styles'
import { parseUnits } from 'viem'
import { roomList } from '../RoomList/config'
import { splitSignature } from 'utils'
import { State } from 'state/types'
import { useDispatch, useSelector } from 'react-redux'
import {
  setCreator,
  setGameActivePlayers,
  setGameCapturedPieces,
  setGameOrientation,
  setGameRoomInfo,
  setGameSignature,
  setLoading,
  setRoomId,
} from 'state/app'
import { useSocket } from 'contexts/SocketContext'

const JoinRoom = () => {
  const { login, logout } = useAuth()
  const { account, chainId } = useActiveWeb3React()
  const { SocketIo } = useSocket()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { toastWarning } = useToast()
  const app = useSelector((state: State) => state.app)
  const currentUser = app.user
  const roomId = useMemo(() => app.roomId, [app.roomId])
  const creator = app.creator[app.roomId]
  const isPrivate = app.isPrivateGame
  const roomDetail = useMemo(() => app.gameRoomInfo, [app.gameRoomInfo])
  const [roomInfoLocal, setRoomInfoLocal] = useState<any>(null)
  const [playerStatus, updatePlayerStatus] = useState<(PlayerStatusType | null)[]>([])

  useEffect(() => {
    if (!isNaN(Number(roomId))) {
      setRoomInfoLocal(roomList[Number(roomId) - 1])
    } else if (roomId) {
      const r = roomId.charAt(0)
      setRoomInfoLocal(roomList[Number(r) - 1])
    }
  }, [roomId, setRoomInfoLocal])

  const [state, setState] = useState({
    roomUsers: [currentUser],
    roomId: roomId,
    currentUser: '',
    isReady: false,
    coipied: false,
    isInvite: false,
  })

  const handleCancel = useCallback(() => {
    SocketIo?.emit('del-custom-room', {
      roomId: roomId,
      currentUser: app.user,
    })

    const obj = { ...app.creator }
    if (roomId && obj[roomId]) {
      delete obj[roomId]
      delete obj[roomId.toString().charAt(0)]
    }

    dispatch(setCreator({ ...obj }))
    dispatch(setGameActivePlayers([]))
    dispatch(setGameRoomInfo(null))
    dispatch(setRoomId(''))

    navigate('/room-list')
  }, [dispatch, navigate, SocketIo, roomId, app.creator, app.user])

  useEffect(() => {
    if (!roomId || !roomDetail) {
      handleCancel()
    }
  }, [handleCancel, roomId, roomDetail])

  useEffect(() => {
    window.addEventListener('beforeunload', handleCancel)

    return () => {
      window.removeEventListener('beforeunload', handleCancel)
    }
  })

  const [value, setValue] = useState('')
  const [, setCopied] = useState(false)

  const [isOponentReady, setIsOponentReady] = useState(false)

  const copyTextToClipboard = useCallback(() => {
    const betA = app.bet
    const gId = app.gameId
    const rId = roomId
    const url = window.location.host + '/room-list/' + gId + '/' + betA + '/' + rId + '/' + creator

    setValue(url)
  }, [app, creator, roomId])

  const syncUsers = useCallback(
    (data) => {
      const { newUser, creator } = data
      if (currentUser) {
        dispatch(setGameActivePlayers([creator, newUser]))
        setState({
          ...state,
          roomUsers: [currentUser, newUser],
        })
      }
    },
    [currentUser, dispatch, state],
  )

  const newUserListener = useCallback(
    (newUserObj) => {
      const statues: (PlayerStatusType | null)[] = []
      for (let i = 0; i < newUserObj.allUsersInRoom.length; i++) {
        const userObj = newUserObj.allUsersInRoom[i]
        statues.push({
          address: userObj.userAddress,
          isOwner: creator === userObj.userAddress,
          inRoom: userObj.inRoom,
          isReady: userObj.isReady,
          joinTimeStamp: moment(userObj.lastDisconnected).unix(),
        })
      }
      if (statues.length === 1) {
        statues.push(null)
      }
      updatePlayerStatus(statues)

      if (newUserObj.userName != app.user) {
        SocketIo?.emit('sync-users', {
          roomId: roomId,
          newUser: newUserObj.userName,
          creator: creator,
        })
      }
      const roomUsers = statues
        .filter((user) => user)
        .map((user: any) => ({
          name: user.address,
          capturedPieces: [],
        }))

      dispatch(setGameActivePlayers(roomUsers.map((user) => user.name)))

      setState({
        ...state,
        roomId: newUserObj.roomId,
        roomUsers: roomUsers.map((user) => user.name),
        isInvite: newUserObj.invite,
      })
    },
    [app.user, creator, SocketIo, dispatch, roomId, state],
  )

  const onKickUser = useCallback(
    (data) => {
      const { userAddress } = data
      if (userAddress === currentUser) {
        toastWarning('Kick out', 'You have been kicked out for inactivity')
        handleCancel()
      }
    },
    [currentUser, handleCancel, toastWarning],
  )

  const isReadListner = useCallback(
    (obj: { address: string; roomId: string; isReady: boolean; roomInfo: any }) => {
      const address = app.user
      if (obj && Object.keys(obj).length > 0 && obj.address !== address) {
        setIsOponentReady(obj.isReady)
      }

      const players: PlayerStatusType[] = []
      for (let i = 0; i < obj.roomInfo.users.length; i++) {
        const userObj = obj.roomInfo.users[i]
        players.push({
          address: userObj.userAddress,
          isOwner: creator === userObj.userAddress,
          inRoom: userObj.inRoom,
          isReady: userObj.isReady,
          joinTimeStamp: moment(userObj.lastDisconnected).unix(),
        })
      }
      updatePlayerStatus(players)
    },
    [app.user, creator],
  )

  const subscribeUser = useCallback(
    (userInfo: { userName: string; roomId: string; betAmount: number }) => {
      setState({ ...state, currentUser: userInfo.userName })
      SocketIo?.emit('user-channel', userInfo)
    },
    [SocketIo, state],
  )

  useEffect(() => {
    copyTextToClipboard()

    SocketIo?.on('del-custom-room', (data) => {
      const { currentUser, creator } = data
      if (currentUser === creator) {
        handleCancel()
        toastWarning('Game creator closed this room')
        navigate('/room-list')
      } else {
        dispatch(
          setGameActivePlayers(app.gameActivePlayers.filter((player) => player !== currentUser)),
        )
        setState({
          ...state,
          isReady: false,
        })
        setIsOponentReady(false)

        const players: (PlayerStatusType | null)[] = []
        players.push({
          address: app.user || '',
          isOwner: true,
          inRoom: true,
          isReady: false,
          joinTimeStamp: null,
        })
        players.push(null)
        updatePlayerStatus(players)
      }
    })

    const getValue = (value) => {
      if (value) {
        return value.toString()
      } else {
        return ''
      }
    }

    subscribeUser({
      userName: getValue(currentUser),
      roomId: getValue(roomId),
      betAmount: Number(app.bet),
    })

    if (roomDetail && roomDetail.gameStarted && roomDetail.users.length > 0 && !roomDetail.ended) {
      SocketIo?.emit('rejoined', {
        joinedUser: app.user,
        roomId,
        auth: app.auth,
      })
      navigate('/gaming', {
        state: app,
      })
    }

    return () => {
      // SocketIo?.removeAllListeners();
      SocketIo?.removeListener('del-custom-room')
      SocketIo?.removeListener('connected')
      SocketIo?.removeListener('rejoined')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    SocketIo?.on('start game', (data) => {
      dispatch(setGameCapturedPieces([]))
      dispatch(setGameRoomInfo(data))
      dispatch(setGameOrientation(currentUser === creator ? 'w' : 'b'))
      navigate('/gaming', {
        state: app,
        replace: true,
      })
    })
    return () => {
      SocketIo?.removeListener('start game')
    }
  }, [SocketIo, currentUser, app, creator, dispatch, navigate])

  useEffect(() => {
    SocketIo?.on('connected', () => {
      if (!document.hidden) {
        const roomId = app.roomId
        SocketIo?.emit('rejoined', {
          roomId,
          joinedUser: app.user,
          auth: app.auth,
        })
        SocketIo?.on('rejoined', () => {
          subscribeUser({
            userName: currentUser ?? '',
            roomId,
            betAmount: Number(app.bet),
          })
        })
      }
    })
  }, [SocketIo, currentUser, app.bet, app.auth, app.roomId, app.user, subscribeUser])

  useEffect(() => {
    SocketIo?.on('user-channel', newUserListener)
    return () => {
      SocketIo?.removeListener('user-channel')
    }
  }, [SocketIo, newUserListener])

  useEffect(() => {
    SocketIo?.on('sync-users', syncUsers)
    return () => {
      SocketIo?.removeListener('sync-users')
    }
  }, [SocketIo, syncUsers])

  useEffect(() => {
    SocketIo?.on('user-ready', isReadListner)
    return () => {
      SocketIo?.removeListener('user-ready')
    }
  }, [SocketIo, isReadListner])

  useEffect(() => {
    SocketIo?.on('kick-user', onKickUser)
    return () => {
      SocketIo?.removeListener('kick-user')
    }
  }, [SocketIo, onKickUser])

  const handlePage = () => {
    SocketIo?.emit('start', {
      roomId: roomId,
      gameId: app.gameId,
      auth: app.auth,
    })
    /**
     * startGame in contract
     */
  }

  const setReady = async (isReady) => {
    if (!chainId || !account) {
      return
    }

    let sig: any = null
    let gameData: any = null

    if (isReady) {
      dispatch(setLoading(true))

      try {
        // Used for testing purposes - wallet <-> browser app switches on mobile
        // if (account === creator) {
        //   SocketIo.io.engine.close()
        // }

        const betA = app.bet
        const gId = app.gameId

        gameData = [
          account,
          getDepositTokenAddress(),
          parseUnits(
            betA && betA !== 'Custom' ? betA.toString() : '0',
            DEPOSIT_TOKEN_DECIMALS,
          ).toString(),
          gId,
        ]
        const typedGameData = {
          domain: {
            chainId: chainId,
            name: 'Chess',
            // verifyingContract: CHESS_CONTRACT_ADDRESS[chainId],
            version: '1',
          },
          types: {
            Game: [
              { name: 'account', type: 'address' },
              { name: 'token', type: 'address' },
              { name: 'betAmount', type: 'uint256' },
              { name: 'gameId', type: 'uint256' },
            ],
          },
          message: {
            account: gameData[0],
            betAmount: gameData[2],
            gameId: gameData[3],
            token: gameData[1],
          },
          primaryType: 'Game' as any,
          account: account as `0x${string}`,
        }

        const signature = await signTypedData(typedGameData)
        sig = splitSignature(signature)
      } catch (e) {
        console.error(e)
      }
    }

    const formattedSig = sig ? JSON.stringify([sig.v, sig.r, sig.s]) : ''
    dispatch(setGameSignature(formattedSig))
    setState({
      ...state,
      isReady: isReady,
    })
    SocketIo?.emit('user-ready', {
      roomId: roomId,
      address: app.user,
      isReady: isReady,
      sig: formattedSig,
      gameData: gameData ? JSON.stringify(gameData) : '',
      auth: app.auth,
    })
    dispatch(setLoading(false))
  }

  const onChange = (e) => {
    setReady(e.target.checked)
  }

  return (
    <JoinRoomContainer>
      <Menu account={account || undefined} login={login} logout={logout} />
      <div className={'joinRoomWrapper'}>
        <ChessLogo width={'148px'} />
        <div className="inviteLink">
          {creator == app.user && !isPrivate && (
            <div className="input-group">
              <input type="text" className="form-control" placeholder="" value={value} disabled />
              <CopyToClipboard text={value} onCopy={() => setCopied(true)}>
                <button
                  className="btn btn-outline-secondary outline-btn"
                  onClick={() => copyTextToClipboard()}
                >
                  Copy link for invitation
                </button>
              </CopyToClipboard>
            </div>
          )}
        </div>
        <div className="roomStatus">
          <div className="leftPart">
            {state.roomUsers &&
            state.roomUsers.length &&
            state.roomUsers.length >= 2 &&
            state.isReady &&
            isOponentReady ? (
              <>
                <div className={'statusTxt'}>YOUR OPPONENT IS HERE</div>
                <img
                  src={'/assets/img/joinOpponent.png'}
                  alt={'joinOpponent'}
                  className={'statusImg'}
                />
              </>
            ) : (
              <>
                <div className={'statusTxt'}>WAITING FOR PLAYER TO ARRIVE</div>
                <img
                  src={'/assets/img/waitingPlayer.png'}
                  alt={'waitingPlayer'}
                  className={'statusImg'}
                />
              </>
            )}
          </div>

          <div className="rightPart">
            {roomInfoLocal && (
              <div className={'rightPart-header'}>
                <div className={'roomIcon'}>{roomInfoLocal.icon}</div>
                <div style={{ textAlign: 'right' }}>
                  <div className={'roomTitle'}>{roomInfoLocal.title}</div>
                  <div className={'roomSubtitle'}>{roomInfoLocal.subTitle}</div>
                </div>
              </div>
            )}
            <div className={'rightPart-content'}>
              <div className={'roomTitle'}>In the Room</div>
              <PlayerStatus
                players={playerStatus}
                isOwner={currentUser === creator}
                onKick={(kickerAddress) => {
                  SocketIo?.emit('kick-user', roomId, kickerAddress)
                }}
              />
              <Checkbox
                onChange={onChange}
                checked={state.isReady}
                disabled={state.roomUsers.length <= 1}
              >
                <span className="readyText">I am Ready to play</span>
              </Checkbox>

              {currentUser !== creator && (
                <div className={'waitingMsg'}>Waiting for host to start the game</div>
              )}
              <div className="btnList">
                <motion.button
                  onClick={handleCancel}
                  className={'backBtn'}
                  whileHover={{ opacity: 0.65 }}
                >
                  Back
                </motion.button>
                {currentUser === creator && (
                  <motion.button
                    onClick={handlePage}
                    disabled={state.roomUsers.length <= 1 || !state.isReady || !isOponentReady}
                    className="startBtn"
                    whileHover={{ opacity: 0.65 }}
                  >
                    Start
                  </motion.button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="footer-links">
          <a
            href="https://docs.babydogeswap.com/babydoge-docs/products/chess/welcome-to-babydoge-chess/testnet-video-tutorial"
            target={'_blank'}
            className="reportBug"
          >
            Video Tutorial
          </a>
          <a
            href="https://docs.babydogeswap.com/babydoge-docs/products/chess/welcome-to-babydoge-chess"
            target={'_blank'}
            className="reportBug"
          >
            Documentation
          </a>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdlvIjYy1_GC8agnzVQfyacHCC1RUpLrmeF8eGBUvyP1R1awA/viewform?usp=sf_link"
            target={'_blank'}
            className="reportBug"
          >
            Report a bug
          </a>
        </div>
      </div>
    </JoinRoomContainer>
  )
}
export default JoinRoom
