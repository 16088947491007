import styled from 'styled-components'

export const RoomListContainer = styled.div`
  background-image: url('/assets/img/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: 100vh;
  overflow: auto;

  .roomListContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    min-height: calc(100vh - 53px);
    justify-content: center;
    .roomListWrapper {
      display: grid;
      justify-content: center;
      // grid-template-columns: 1fr 1fr 1fr 1fr;
      // align-items: center;
      // grid-gap: 18px;
      // flex-wrap: wrap;
      margin: 11px 140px 66px;
    }
  }

  @media (max-width: 780px) {
    .roomListContainer {
      .roomListWrapper {
        grid-template-columns: 1fr;
      }
    }
  }
`
