import { useWeb3React } from 'utils/wagmi/useWeb3React'
import { useEffect, useState } from 'react'
import { CURRENT_CHAIN } from '../constants/chains'
/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = () => {
  const web3React = useWeb3React()
  const [isAttemptingConnect, setIsAttemptingConnect] = useState(true)
  const [wagmiInited, setWagmiInited] = useState(false)
  const { isConnecting, chainId, account } = web3React

  useEffect(() => {
    if (!wagmiInited) {
      setWagmiInited(true)
    } else {
      if (isConnecting && !isAttemptingConnect) {
        setIsAttemptingConnect(true)
      } else if (!isConnecting) {
        setIsAttemptingConnect(false)
      }
    }
  }, [account, isAttemptingConnect, isConnecting, wagmiInited])

  return {
    ...web3React,
    account: chainId === CURRENT_CHAIN ? account : null,
    isAttemptingConnect,
  }
}

export default useActiveWeb3React
