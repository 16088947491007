import styled from 'styled-components'

export const HomeContainer = styled.div`
  background-image: url('/assets/img/background.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;

  .playGameWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .playNowBtn {
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 20px;
    color: #002636;
    background-color: #fff;
    border: none;
    outline: none;
    padding: 13px 54px;
    border-radius: 54px;
  }

  .playGame {
    height: calc(100vh - 53px);
    min-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .leaderBoardBtn {
    color: white;
    font-family: 'Poppins', serif;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;

    width: 120px;
    height: 30px;
    border: 1px solid #fff;
    border-radius: 30px;
    background-color: transparent;
  }

  .disclaimer {
    font-size: 12px;
    line-height: 18px;
    color: white;
    font-family: 'Poppins', serif;

    max-width: 600px;
    text-align: center;
    margin-top: 20px;
  }

  @media only screen and (max-width: 640px) {
    .disclaimer {
      max-width: 90%;
    }
  }
`
