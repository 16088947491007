import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 36.618 75.604" {...props}>
      <defs>
        <clipPath id="clip-path-yard">
          <rect
            id="Rectangle_873"
            data-name="Rectangle 873"
            width="36.618"
            height="75.604"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_29" data-name="Group 29" clipPath="url(#clip-path-yard)">
        <path
          id="Path_87"
          data-name="Path 87"
          d="M34.926,34.335,19.429.717a1.233,1.233,0,0,0-2.24,0L1.692,34.335A18.3,18.3,0,0,0,17.076,60.25v14.12a1.233,1.233,0,0,0,2.467,0V60.25A18.3,18.3,0,0,0,34.926,34.335m-3.284,16.2a15.75,15.75,0,0,1-12.1,7.243V19.506a1.233,1.233,0,1,0-2.467,0V57.774A15.826,15.826,0,0,1,3.932,35.367L18.309,4.18,32.686,35.367A15.763,15.763,0,0,1,31.643,50.53"
          transform="translate(0)"
          fill="#006eb9"
        />
      </g>
    </Svg>
  )
}

export default Icon
