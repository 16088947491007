import { Button, Input, Modal } from 'antd'
import axios from 'axios'
import { Flex } from 'components/Flex'
import { motion } from 'framer-motion'
import useActiveWeb3React from 'hook/useActiveWeb3React'
import useChessContract from 'hook/useChessContract'
import { useRouterContract } from 'hook/useRouterContract'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { nFormatter } from 'utils'
import { convertUSD } from 'utils/convertUSD'
import Menu from '../../components/Menu/Menu'
import { RoomItem } from '../../components/RoomItem'
import { ChessLogo } from '../../components/Svg'
import useAuth from '../../hook/useAuth'
import { useToast } from '../../hook/useToast'
import {
  setBet,
  setCreator,
  setGameId,
  setIsPrivateGame,
  setLoading,
  setRoomId,
  setGameRoomInfo,
  setGameActivePlayers,
  setGameCapturedPieces,
} from '../../state/app'
import { useSocket } from 'contexts/SocketContext'
import { roomList } from './config'
import { RoomListContainer } from './styles'
import API from 'api/api'
import { State } from 'state/types'
import { delay } from 'utils/delay'

export default function RoomList() {
  const { login, logout } = useAuth()
  const { price } = useRouterContract()
  const { SocketIo } = useSocket()
  const { toastError } = useToast()
  const { isAttemptingConnect, account } = useActiveWeb3React()
  const navigate = useNavigate()
  const [roomDetailObject, setRoomDetailObject] = useState()
  const roomDetailObjectRef = useRef(roomDetailObject)
  const dispatch = useDispatch()
  const app = useSelector((state: State) => state.app)
  const [customRooms, setCustomRooms] = useState<any[]>([])
  const [roomStatus, setRoomStatus] = useState({})
  const { balances } = useChessContract()
  const [state, setState] = useState({
    currentUsers: [],
    isModalVisible: false,
    isCustomModalVisible: false,
    isBSCVisible: false,
    isPaymentModalVisible: false,
    roomDetails: {
      gameStarted: false,
      allUsersInGame: [] as any,
      users: [] as any,
      ended: false,
    },
  })
  const roomDetailsRef = useRef(state.roomDetails)

  const { gid, amount, rid, creator } = useParams()
  const isPrivateModalVisible = !!gid && !!amount && !!rid && !!creator

  const [betAmount, setBetAmount] = useState<any | null>(0)
  const allUsersInGame = state.roomDetails.users ? state.roomDetails.users : []

  useEffect(() => {
    if (!account && !isAttemptingConnect) {
      navigate('/')
    }
  }, [account, isAttemptingConnect, navigate])

  const handleJoinRoom = useCallback(
    (data) => {
      dispatch(setRoomId(data.roomId))
      dispatch(setCreator({ ...app.creator, [data.roomId]: data.creator }))
      dispatch(
        setGameRoomInfo(
          roomDetailObjectRef.current ? roomDetailObjectRef.current : roomDetailsRef.current,
        ),
      )
      if (data.userAddress === app.user) {
        navigate('/join-room')
      }
    },
    [app, dispatch, navigate],
  )

  const handleJoinMyRoom = useCallback(
    async (amount, gameId, roomId, account, isNew, isInvite, isPrivate?: boolean) => {
      const allUsersInGame = state.roomDetails.allUsersInGame
        ? state.roomDetails.allUsersInGame
        : []
      if (state.roomDetails.gameStarted && !allUsersInGame.includes(account)) {
        navigate('/')
      }
      if (!app.creator[roomId]) {
        dispatch(setCreator({ ...app.creator, [roomId]: creator ?? 'its me' }))
      }
      dispatch(setGameId(gameId))
      dispatch(setBet(amount.toString()))

      let res
      try {
        res = await API.getRoomCountByAddress(account)
      } catch {
        return
      }
      const roomCount = res.data.count
      if (roomCount > 0) {
        toastError('Multiple games are not allowed')
        return
      }
      if (Number(amount) > 0) {
        if (Number(balances) >= amount) {
          SocketIo?.emit('join-room', {
            roomId,
            betAmount: amount,
            userAddress: account,
            gameId,
            isNew,
            isInvite,
            isPrivate: isPrivate || app.isPrivateGame,
            creator: app.creator[roomId] ?? '',
            auth: app.auth,
          })
        } else {
          toastError('Low balance')
          navigate('/room-list')

          return
        }
      } else {
        SocketIo?.emit('join-room', {
          roomId: roomId,
          betAmount: amount,
          userAddress: account,
          gameId,
          isNew,
          isInvite,
          isPrivate: isPrivate || app.isPrivateGame,
          creator: app.creator[roomId] ?? '',
          auth: app.auth,
        })
      }
    },
    [
      SocketIo,
      app.auth,
      app.creator,
      app.isPrivateGame,
      balances,
      creator,
      dispatch,
      navigate,
      state.roomDetails.allUsersInGame,
      state.roomDetails.gameStarted,
      toastError,
    ],
  )

  const AlertJsx = (): any => {
    let jsx
    if (account && allUsersInGame.length > 1 && !allUsersInGame.includes(account)) {
      jsx = jsx = (
        <Modal
          title="Game Bet"
          open={isPrivateModalVisible}
          cancelButtonProps={{ style: { display: 'none' } }}
          onOk={async () => {
            setState({
              ...state,
              roomDetails: {
                gameStarted: false,
                allUsersInGame: [] as any,
                users: [] as any,
                ended: false,
              },
            })
            roomDetailsRef.current = {
              gameStarted: false,
              allUsersInGame: [] as any,
              users: [] as any,
              ended: false,
            }
            navigate('/room-list')
          }}
        >
          <div> This game is fulfilled. Please join to another game.</div>
        </Modal>
      )
      // DEAD CODE?
    } else if (
      account &&
      ((state.currentUsers.length < 2 && !state.roomDetails.gameStarted) ||
        (allUsersInGame.length > 0 && allUsersInGame.includes(account))) &&
      !state.roomDetails.ended
    ) {
      jsx = (
        <Modal
          title="Join Private Match"
          open={isPrivateModalVisible}
          onOk={async () => await handleJoinMyRoom(amount, gid, rid, account, false, true)}
          onCancel={() => navigate('/')}
        >
          {amount === '0' ? (
            <div>
              Join our Online FREE Chess Club and connect and compete with your friends in matches
            </div>
          ) : (
            <div>{nFormatter(amount, 1)} game bet join me to play now</div>
          )}
        </Modal>
      )
    } else if (!account) {
      jsx = (
        <Modal
          title="Game Bet"
          open={isPrivateModalVisible}
          onOk={() => navigate('/')}
          onCancel={() => navigate('/')}
        >
          <div>Please paste this invitation link again after connect wallet!</div>
        </Modal>
      )
    }
    return jsx
  }

  const handleGetRoomUser = useCallback(
    async (data) => {
      if (data) {
        setState({
          ...state,
          currentUsers: data.roomInfo.users,
          roomDetails: data.roomInfo,
        })
        roomDetailsRef.current = data.roomInfo
      }
    },
    [state],
  )

  const handleGetRoomInfo = useCallback(
    (data) => {
      const roomId = app.roomId
      if (roomId) {
        if (data.currentUser === app.user) {
          setState({ ...state, isCustomModalVisible: true })
        }
      }
    },
    [app, state],
  )

  const handleCustomConfirm = () => {
    setState({ ...state, isCustomModalVisible: false, isModalVisible: true })
  }

  const handleCustomBack = () => {
    setState({ ...state, isCustomModalVisible: false })
    dispatch(setLoading(false))
  }

  const handleCustomRoom = useCallback(
    async (amount, game_id, creator, roomDetail) => {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}gamePlayingRoomCountByAddress`,
        {
          params: {
            address: account,
          },
        },
      )

      const roomCount = res.data.count
      if (roomCount > 0) {
        toastError('Multiple games are not allowed')
        return
      }
      setRoomDetailObject(roomDetail)
      roomDetailObjectRef.current = roomDetail
      dispatch(setRoomId(roomDetail.roomId))
      dispatch(setGameId(game_id))
      dispatch(setCreator({ ...app.creator, [roomDetail.roomId]: creator }))
      setBetAmount(amount?.toString() ?? '0')
      handleJoinMyRoom(amount, game_id, roomDetail.roomId, account, false, false)
    },
    [account, app.creator, dispatch, handleJoinMyRoom, toastError],
  )

  const handlePlayNow = async () => {
    dispatch(setIsPrivateGame(true))
    handleConfirm(true)
  }

  const handleConfirm = async (isPrivate?: boolean) => {
    const storedRoomId = app.roomId
    dispatch(setCreator({ ...app.creator, [storedRoomId]: app.user }))
    if (storedRoomId === '8' && !state.isModalVisible) {
      handleCustomConfirm()
    } else {
      const d = new Date()
      let _id = d.getTime().toString()
      const roomId = app.roomId
      _id += roomId
      dispatch(setGameId(_id))
      dispatch(setBet(betAmount?.toString() ?? '0'))
      handleJoinMyRoom(betAmount, _id, roomId, account, true, false, isPrivate)
    }
  }

  const handleConfirmPay = async () => {
    dispatch(setRoomId('8'))
    await handleConfirm()
  }
  const handleCancel = () => {
    setState({ ...state, isModalVisible: false })
  }
  const handleCancelPay = () => {
    setState({ ...state, isPaymentModalVisible: false })
  }

  const init = useCallback(async () => {
    if (rid) {
      SocketIo?.emit('getUsersCount', { room: rid, callback: handleGetRoomUser })
    }
  }, [rid, handleGetRoomUser, SocketIo])

  const customRoomJsx = useCallback((): any => {
    if (customRooms.length) {
      let room_id = app.roomId + '-' + betAmount
      if (room_id.startsWith('8')) {
        room_id = '8-'
      }
      const jsx = <div>Hosted Games</div>
      const jsxA = [jsx]
      for (let i = 0; i < customRooms.length; i++) {
        for (const key in customRooms[i]) {
          if (!key.startsWith(room_id)) {
            continue
          }
          if (customRooms[i][key].ended != true && customRooms[i][key].isPrivate) {
            if (
              (customRooms[i][key].nonce && customRooms[i][key].started === false) ||
              (customRooms[i][key].users.includes(account) && !customRooms[i][key].ended)
            ) {
              const amt = customRooms[i][key].betAmount
              const gameId = customRooms[i][key].gameId
              const roomDetail = customRooms[i][key]
              const hostUser = customRooms[i][key].creator
              jsxA.push(
                <Button
                  key={`${key}_${i}`}
                  className="room-ids-btn"
                  onClick={() => {
                    handleCustomRoom(amt, gameId, hostUser, roomDetail)
                  }}
                >
                  <Flex justifyContent="space-between">
                    <span>{`${customRooms[i][key].users[0]}`}</span>
                    <text>
                      {nFormatter(amt, 1)} ({`~$${nFormatter(convertUSD(amt, price), 1)}`})
                    </text>
                  </Flex>
                </Button>,
              )
            }
          }
        }
      }
      return jsxA
    }
    return []
  }, [account, app.roomId, betAmount, customRooms, handleCustomRoom, price])

  const handleUpdateCustomRooms = useCallback(
    (data) => {
      const { customRooms, timer } = data
      setCustomRooms(customRooms)

      if (customRooms.length > 0) {
        const newStatus = {}
        for (let i = 0; i < customRooms.length; i++) {
          for (const key in customRooms[i]) {
            const roomId = key.split('-')[0]
            if (!newStatus[roomId]) {
              newStatus[roomId] = {
                waiting: 0,
                playing: 0,
                previewsRoomId: null,
              }
            }
            if (!customRooms[i][key].ended && customRooms[i][key].isPrivate) {
              if (customRooms[i][key].nonce && !customRooms[i][key].gameStarted) {
                newStatus[roomId].previewsRoomId = null
                newStatus[roomId].waiting += 1
              } else if (customRooms[i][key].gameStarted) {
                if (customRooms[i][key].allUsersInGame.includes(account)) {
                  newStatus[roomId].previewsRoomId = key
                  newStatus[roomId].previousRoomTimer = timer[key]
                } else {
                  newStatus[roomId].previewsRoomId = null
                  newStatus[roomId].previousRoomTimer = null
                  newStatus[roomId].playing += 1
                }
              }
            }
          }
        }
        setRoomStatus(newStatus)
      }
    },
    [setRoomStatus, account, setCustomRooms],
  )

  useEffect(() => {
    SocketIo?.on('room-info', handleGetRoomInfo)
    return () => {
      SocketIo?.removeListener('room-info')
    }
  }, [handleGetRoomInfo, SocketIo])

  useEffect(() => {
    SocketIo?.on('resume-game', (data) => {
      const { capturePieces, roomInfo } = data
      dispatch(setGameRoomInfo(roomInfo))
      dispatch(setGameCapturedPieces([...(capturePieces ?? [])]))
      dispatch(setGameActivePlayers([...roomInfo.users]))
      navigate('/gaming', {
        state: app,
      })
    })
    return () => {
      SocketIo?.removeListener('resume-game')
    }
  }, [app, creator, dispatch, navigate, SocketIo])

  useEffect(() => {
    SocketIo?.on('join-room', handleJoinRoom)
    return () => {
      SocketIo?.removeListener('join-room')
    }
  }, [handleJoinRoom, SocketIo])

  useEffect(() => {
    SocketIo?.on('update-custom-rooms', handleUpdateCustomRooms)
    return () => {
      SocketIo?.removeListener('update-custom-rooms')
    }
  }, [handleUpdateCustomRooms, SocketIo])

  useEffect(() => {
    SocketIo?.emit('update-custom-rooms')

    init()

    return () => {
      // SocketIo?.removeAllListeners();
    }
  }, [init, SocketIo])

  const handleRoom = useCallback(
    async (roomId, betAmount) => {
      if (!app.user) {
        navigate('/')
        return
      }

      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}gamePlayingRoomCountByAddress`,
        {
          params: {
            address: account,
          },
        },
      )

      const roomCount = res.data.count
      if (roomCount > 0) {
        toastError('Multiple games are not allowed')
        return
      }

      dispatch(setRoomId(roomId))
      dispatch(setCreator({ ...app.creator, [roomId]: app.user }))
      setBetAmount(betAmount?.toString() ?? '0')

      // redux dispatches are not the quickest, on a preformant device
      // the dispatches above and the emited event/listener become a race condition
      // as the room-info listener relies on app.roomId being set in time
      // this mitigates the issue
      await delay(200)

      SocketIo?.emit('room-info', {
        roomId: roomId,
        currentUser: app.user,
      })
    },
    [app, account, SocketIo, dispatch, navigate, toastError],
  )

  const handleResume = useCallback(
    async (roomId) => {
      dispatch(setRoomId(roomId))

      SocketIo?.emit('resume-game', {
        roomId: roomId,
        user: app.user,
        auth: app.auth,
      })
    },
    [app, SocketIo, dispatch],
  )

  const jsx = AlertJsx()
  const roomId = app.roomId
  const { subTitle } = roomList.find(({ id }) => roomId === id.toString()) ?? {}
  const roomsTitle = subTitle ? subTitle : `Room ${roomId}`

  return (
    <div>
      {jsx}
      <Modal open={state.isModalVisible} footer={null} closable={false} key={'customBetModal'}>
        <div className="room-info-container">
          <p className="room-heading" key={'custom-bet-modal'}>
            {roomId && roomId === '8' ? 'Enter amount for bet' : 'Create new room'}
          </p>
          <div className="mb-4 mt-2 room-info-body">
            {roomId && roomId === '8' ? (
              <Input
                onChange={(e) => {
                  setBetAmount(Number(e.target.value))
                }}
                type="number"
                placeholder="0"
              />
            ) : null}
          </div>
          <div className="room-info-button-container d-flex justify-content-between mt-4">
            <motion.button
              // type="default"
              onClick={handleCancel}
              className="room-info-create-btn"
              whileHover={{ opacity: 0.65 }}
            >
              Cancel
            </motion.button>
            <motion.button
              //   type="primary"
              disabled={betAmount < 5}
              onClick={() => handleConfirm()}
              className="room-info-create-btn"
              whileHover={{ opacity: 0.65 }}
            >
              Confirm
            </motion.button>
          </div>
        </div>
      </Modal>

      <Modal
        key={'playModal'}
        open={state.isCustomModalVisible}
        footer={null}
        closable={true}
        onCancel={handleCustomBack}
      >
        <div className="room-info-container">
          <p className="room-heading">{roomsTitle}</p>
          <div className="mb-4 mt-2 room-info-body">
            {customRoomJsx().map((element, idx) => (
              <div key={`${roomId}_${idx}`}>{element}</div>
            ))}
          </div>
          <div className="room-info-button-container d-flex justify-content-between mt-4">
            <motion.button
              onClick={handlePlayNow}
              className="room-info-create-btn"
              whileHover={{ opacity: 0.65 }}
            >
              Host Game
            </motion.button>
            <motion.button
              onClick={() => {
                dispatch(setIsPrivateGame(false))
                handleConfirm(false)
              }}
              className="room-info-create-btn"
              whileHover={{ opacity: 0.65 }}
            >
              Private Match
            </motion.button>
          </div>
        </div>
      </Modal>
      <Modal
        key={'binanceOnlyModal'}
        title="Binance Smart Chain only"
        open={state.isBSCVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={async () => navigate('/')}
      >
        <div> Please change wallet to Binance Smart Chain</div>
      </Modal>
      <Modal
        open={state.isPaymentModalVisible}
        footer={[
          <Button type="default" onClick={handleCancelPay} key={'cancel'}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleConfirmPay} key={'pay'}>
            Pay
          </Button>,
        ]}
      >
        <p>
          You need to pay $ <b>{betAmount}</b> to play this game.
        </p>
      </Modal>
      <RoomListContainer>
        <Menu account={account || undefined} login={login} logout={logout} />
        <div className={'roomListContainer'}>
          <ChessLogo width={'148px'} />
          <div className={'roomListWrapper'}>
            {roomList.map((room) => (
              <RoomItem
                room={room}
                key={`room_item_${room.id}_${room.betAmt}`}
                roomStatus={roomStatus[room.id]}
                onClick={() => handleRoom(room.id, room.betAmt)}
                onResume={() => handleResume(roomStatus[room.id].previewsRoomId)}
              />
            ))}
          </div>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSdlvIjYy1_GC8agnzVQfyacHCC1RUpLrmeF8eGBUvyP1R1awA/viewform?usp=sf_link"
            target={'_blank'}
            className="reportBug"
          >
            Report a bug
          </a>
        </div>
      </RoomListContainer>
    </div>
  )
}
