import { useModal } from '../../components/Modal'
import { ConfirmDrawModal } from './index'

interface ReturnType {
  onPresentConfirmDrawModal: () => void
}

const useConfirmDrawModal = (onAcceptDraw?: () => void, onRejectDraw?: () => void): ReturnType => {
  const [onPresentConfirmDrawModal] = useModal(
    <ConfirmDrawModal onAcceptDraw={onAcceptDraw} onRejectDraw={onRejectDraw} />,
  )
  return { onPresentConfirmDrawModal }
}

export default useConfirmDrawModal
