import useInterval from 'hook/useInterval'
import useIsWindowVisible from 'hook/useIsWindowVisible'
import { createContext, FC, ReactNode, useState } from 'react'
interface WithChildren {
  children?: ReactNode
}

export const FAST_INTERVAL = 10000
export const MIDDLE_INTERVAL = 30000
export const SLOW_INTERVAL = 60000

const createRefreshContext = (interval: number) => {
  const RefreshContext = createContext(0)
  return {
    Context: RefreshContext,
    Provider: ({ children }) => {
      const [count, setCount] = useState(0)

      useInterval(
        () => {
          setCount((c) => c + 1)
        },
        useIsWindowVisible() ? interval : undefined,
        false,
      )

      return <RefreshContext.Provider value={count}>{children}</RefreshContext.Provider>
    },
  }
}

export const SlowRefresh = createRefreshContext(SLOW_INTERVAL)
export const MiddleRefresh = createRefreshContext(MIDDLE_INTERVAL)
export const FastRefresh = createRefreshContext(FAST_INTERVAL)

const RefreshContextProvider: FC<WithChildren> = ({ children }) => {
  return (
    <SlowRefresh.Provider>
      <MiddleRefresh.Provider>
        <FastRefresh.Provider>{children}</FastRefresh.Provider>
      </MiddleRefresh.Provider>
    </SlowRefresh.Provider>
  )
}

export { RefreshContextProvider }
