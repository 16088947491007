// Network chain ids

export enum CHAIN_ID {
  BSC = 56,
  BSC_TESTNET = 97,
}

export const NETWORK_LABEL = {
  [CHAIN_ID.BSC]: 'BSC',
  [CHAIN_ID.BSC_TESTNET]: 'BSC Testnet',
}

export const BLOCK_EXPLORER = {
  [CHAIN_ID.BSC]: 'https://bscscan.com',
  [CHAIN_ID.BSC_TESTNET]: 'https://testnet.bscscan.com/',
}

export const CURRENT_CHAIN = Number(process.env.REACT_APP_NETWORK_ID)
