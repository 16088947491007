import { Modal } from '../../components/Modal'
import { Flex } from '../../components/Flex'
import { motion } from 'framer-motion'
import styled from 'styled-components'

interface Props {
  onCallback?: () => void
  onDismiss?: () => void
}

const DrawModalWrapper = styled.div`
  .desc {
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    color: #fff;
  }
  .yesBtn {
    width: 102px;
    height: 27px;
    border-radius: 13.5px;
    background: #fff;
    border: 1px solid #fff;
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #000b10;
  }

  .noBtn {
    width: 102px;
    height: 27px;
    border-radius: 13.5px;
    background: transparent;
    border: 1px solid #fff;
    font-family: Poppins, serif;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
  }
`
export default function RequestDrawModal(props: Props) {
  const { onDismiss, onCallback } = props
  return (
    <Modal onDismiss={onDismiss} title={'Offer Draw'}>
      <DrawModalWrapper>
        <div className={'desc'}>You are requesting to draw this game!</div>
        <div className={'desc'}>Are you confirming to send draw request?</div>

        <Flex justifyContent={'space-between'} style={{ gridGap: '30px', marginTop: '24px' }}>
          <motion.button className={'noBtn'} onClick={onDismiss}>
            No
          </motion.button>
          <motion.button
            className={'yesBtn'}
            onClick={() => {
              onCallback && onCallback()
              onDismiss && onDismiss()
            }}
          >
            Yes
          </motion.button>
        </Flex>
      </DrawModalWrapper>
    </Modal>
  )
}
