import { CHAIN_ID } from 'constants/chains'

export const CHESS_CONTRACT_ADDRESS = {
  [CHAIN_ID.BSC]: '0x0',
  [CHAIN_ID.BSC_TESTNET]: '0x5410e5B1a2d45A3D271Ef6071F779f017c169bc3',
}

export const BABYDOGE_CONTRACT_ADDRESS = {
  [CHAIN_ID.BSC]: '0xc748673057861a797275cd8a068abb95a902e8de',
  [CHAIN_ID.BSC_TESTNET]: '0x4985f07c5Ac918376080785f179D9595F972E5cB',
}

export const DEPOSIT_TOKEN_ADDRESS = {
  [CHAIN_ID.BSC]: '0xc748673057861a797275cd8a068abb95a902e8de',
  [CHAIN_ID.BSC_TESTNET]: '0x4985f07c5Ac918376080785f179D9595F972E5cB',
}

export const BabyDogeRouter = {
  [CHAIN_ID.BSC]: '0xC9a0F685F39d05D835c369036251ee3aEaaF3c47',
  [CHAIN_ID.BSC_TESTNET]: '0xa18f36819023ABe64a1D811E529f6bc83fEF9572',
}

export const DEPOSIT_TOKEN_DECIMALS = 9
