import { YardRoom } from '../../components/Svg'

export const roomList = [
  {
    id: 1,
    title: 'Room 1',
    betAmt: 0,
    subTitle: 'The Yard',
    icon: <YardRoom height={'58px'} />,
  },
  // {
  //   id: 2,
  //   title: 'Room 2',
  //   betAmt: 1000000000,
  //   subTitle: 'The Hall',
  //   icon: <HallRoom height={'58px'} />,
  // },
  // {
  //   id: 3,
  //   title: 'Room 3',
  //   betAmt: 2000000000,
  //   subTitle: 'The Dining Room',
  //   icon: <DinningRoom height={'58px'} />,
  // },
  // {
  //   id: 4,
  //   title: 'Room 4',
  //   betAmt: 5000000000,
  //   subTitle: 'The Basement',
  //   icon: <BasementRoom height={'58px'} />,
  // },
  // {
  //   id: 5,
  //   title: 'Room 5',
  //   betAmt: 10000000000,
  //   subTitle: "The Bishop's Room",
  //   icon: <BishopRoom height={'58px'} />,
  // },
  // {
  //   id: 6,
  //   title: 'Room 6',
  //   betAmt: 50000000000,
  //   subTitle: 'The Stable',
  //   icon: <StableRoom height={'58px'} />,
  // },
  // {
  //   id: 7,
  //   title: 'Room 7',
  //   betAmt: 100000000000,
  //   subTitle: "The Prisoner's Tower",
  //   icon: <PresonerRoom height={'58px'} />,
  // },
  // {
  //   id: 8,
  //   title: 'Room 8',
  //   betAmt: 'Custom',
  //   subTitle: "The King's Room",
  //   icon: <KingRoom height={'58px'} />,
  // },
]
