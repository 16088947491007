import { Flex } from 'components/Flex'
import { motion } from 'framer-motion'
import useActiveWeb3React from 'hook/useActiveWeb3React'
import useOutsideAlerter from 'hook/useOutsideAlerter'
import lottie from 'lottie-web'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import loadingJson from '../../components/Loading/loading.json'
import Menu from '../../components/Menu/Menu'
import { useWalletModal } from '../../components/WalletModal'
import useAuth from '../../hook/useAuth'
import { HomeContainer } from './styles'

// const TopUpMenu = () => {
//   const { mint } = useERC20(getBabydogeAddress())
//   return (
//     <Flex flexDirection={'row'} style={{ gap: '20px' }} mt="20px">
//       <motion.button
//         className={'leaderBoardBtn'}
//         whileHover={{ opacity: 0.65 }}
//         onClick={() => window.open('https://testnet.bnbchain.org/faucet-smart', '_blank')}
//       >
//         BNB Faucet
//       </motion.button>
//       <motion.button
//         className={'leaderBoardBtn'}
//         whileHover={{ opacity: 0.65 }}
//         disabled={!mint.write}
//         onClick={() => mint.write && mint.write()}
//       >
//         TopUp
//       </motion.button>
//     </Flex>
//   )
// }

export default function Home() {
  const { login, logout } = useAuth()
  const { account } = useActiveWeb3React()
  const navigate = useNavigate()
  const { onPresentConnectModal } = useWalletModal(login)
  const [, setShowBalanceView] = useState(!isMobile)
  const balanceViewRef = useRef(null)
  useOutsideAlerter(balanceViewRef, () => isMobile && setShowBalanceView(false))

  useEffect(() => {
    const animation = lottie.loadAnimation({
      container: document.getElementById('rewards_updating'),
      animationData: loadingJson,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'animation',
    })
    animation.play()

    return () => {
      lottie.destroy('animation')
    }
  }, [])

  const handlePlayNow = () => {
    if (account) {
      navigate('/room-list')
    } else {
      onPresentConnectModal()
    }
  }

  return (
    <HomeContainer>
      <Menu login={login} logout={logout} />
      <div className="playGame">
        <div className={'playGameWrapper'}>
          <Flex flexDirection={'column'} alignItems="center" style={{ gap: '10px' }}>
            <img className="chessLogo" src={'/assets/img/chess.png'} alt={'chess logo'} />
            <motion.button
              className={'playNowBtn'}
              whileHover={{ opacity: 0.65 }}
              onClick={handlePlayNow}
            >
              PLAY NOW
            </motion.button>
            {/* {account && isMobile && (
              <motion.button
                className={'leaderBoardBtn'}
                whileHover={{ opacity: 0.65 }}
                onClick={() => setShowBalanceView(!showBalanceView)}
              >
                My Balance
              </motion.button>
            )} */}
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSdlvIjYy1_GC8agnzVQfyacHCC1RUpLrmeF8eGBUvyP1R1awA/viewform?usp=sf_link"
              target={'_blank'}
              className="reportBug"
            >
              Report a bug
            </a>
          </Flex>
          {/* {!isDesktop && account && chainId == CHAIN_ID.BSC_TESTNET && <TopUpMenu />} */}
        </div>
        {/* {account && showBalanceView && (
          <div ref={balanceViewRef}>
            <BalanceView />
          </div>
        )} */}
      </div>
    </HomeContainer>
  )
}
