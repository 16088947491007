import { ChainId } from '@babydogeswap/sdk'
import { Chain } from 'wagmi'

export enum NetworkName {
  BSC = 'bsc',
  BSC_TESTNET = 'bsc-testnet',
}

const bscExplorer = { name: 'BscScan', url: 'https://bscscan.com' }
const bscTestExplorer = { name: 'BscScan', url: 'https://testnet.bscscan.com' }

export const MAIN_NODE =
  'https://rpc-bsc-mainnet-chess.babydogeswap.com/255f9545ea739169677278eb580e9141d0b597d4'
export const TEST_NODE =
  'https://rpc-bsc-testnet-chess.babydogeswap.com/75d8110fbd7647e2b0357b1a415b36eeadf7207a'

export const bsc: Chain = {
  id: ChainId.MAINNET,
  name: 'BNB Smart Chain',
  network: NetworkName.BSC,
  rpcUrls: {
    public: { http: [MAIN_NODE] },
    default: { http: [MAIN_NODE] },
  },
  blockExplorers: {
    default: bscExplorer,
    etherscan: bscExplorer,
  },
  nativeCurrency: {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18,
  },
}

const bscTest: Chain = {
  id: ChainId.TESTNET,
  name: 'BNB Smart Chain Testnet',
  network: NetworkName.BSC_TESTNET,
  nativeCurrency: {
    decimals: 18,
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
  },
  rpcUrls: {
    public: { http: [TEST_NODE] },
    default: { http: [TEST_NODE] },
  },
  blockExplorers: {
    default: bscTestExplorer,
  },
  testnet: true,
}
export const CHAINS = [bscTest, bsc]
