import 'normalize.css'
import * as React from 'react'
import '../../stylesheets/App.css'
// import useAudio from 'hook/useAudio';
import withRouter from 'hook/withRouter'
import { GameBoard } from '../../components/GameBoard'
import { ChessLogo } from '../../components/Svg'
import { GamingContainer } from './styles'
import { AppState } from 'state/types'
import { Dispatch } from '@reduxjs/toolkit'
import { setMessages, setGameActivePlayers, setGameCapturedPieces } from 'state/app'
import { Socket } from 'socket.io-client'

interface AppProps {
  roomId: string
  location: any
}

let processed = false

interface GameState {
  SocketIo: Socket | null
  secondUserStatus: boolean
  isReset: boolean
  fen: string
  currentUser: string
  messagesArray: any[]
  hasUnreadMsg: boolean
  intervalSeconds: any
  gameStatus: {
    message: string
    type: 'success' | 'info' | 'warning' | 'error'
    showIcon: boolean
    checked: boolean
    color: string
  }
  app: AppState
  dispatch: Dispatch<any>
}

class Gaming extends React.Component<AppProps, GameState> {
  public constructor(props: any, context: any) {
    super(props, context)
    this.state = {
      SocketIo: props.SocketIo,
      isReset: false,
      secondUserStatus: false,
      fen: '',
      currentUser: '',
      messagesArray: [],
      intervalSeconds: 0,
      hasUnreadMsg: false,
      gameStatus: {
        message: 'Welcome to BabyDoge Chess.',
        type: 'info',
        showIcon: false,
        checked: false,
        color: 'White',
      },
      app: props.state,
      dispatch: props.dispatch,
    }

    this.state.SocketIo?.on('board-update', this.updateBoardListener)
    this.state.SocketIo?.on('user-channel', this.newUserListener)
    this.state.SocketIo?.on('message', this.newMessageListener)
    this.state.SocketIo?.on('get-board-info', this.handleGetBoardInfo)
    this.state.SocketIo?.on('captured-pieces', this.handleRoomCapturedPieces)
    this.state.SocketIo?.on('accept-offer-draw', () => {
      this.setState({
        gameStatus: {
          ...this.state.gameStatus,
          message: 'DRAW',
        },
      })
    })
  }

  async componentDidMount() {
    const address = this.state.app.user
    const roomId = this.state.app.roomId
    const gameId = this.state.app.gameId
    const allMessages: Array<any> = this.state.app.messages[gameId || ''] || []

    this.setState({
      ...this.state,
      currentUser: address ?? '',
      messagesArray: allMessages.length > 0 ? allMessages : [],
    })

    this.state.SocketIo?.emit('get-board-info', { roomId, gameId })
    this.state.SocketIo?.emit('user-channel', {
      userName: address ?? '',
      roomId: roomId ?? '',
    })
  }

  async componentDidUpdate(prevProps, prevState) {
    if (!processed && this.state.app.gameOrientation === 'b') {
      processed = true
      this.setState({
        ...this.state,
      })
    }

    if (this.state.app.gameActivePlayers !== prevState.app.gameActivePlayers) {
      const roomUsersLength = this.state.app.gameActivePlayers.length
      switch (roomUsersLength) {
        case 1:
          this.otherUserTimeout = setInterval(() => {
            this.setState({ intervalSeconds: this.state.intervalSeconds + 1 })
          }, 1000)
          break
        case 2:
          clearInterval(this.otherUserTimeout)
          this.setState({ intervalSeconds: 0 })
          break
      }
    }
  }

  onCapturedPieces = (capturedPieces) => {
    if (capturedPieces?.length) {
      this.state.dispatch(setGameCapturedPieces(capturedPieces))
      this.state.SocketIo?.emit('captured-pieces', {
        roomId: this.state.app.roomId,
        auth: this.state.app.auth,
        capturedPieces,
      })
    }
  }

  public render() {
    return (
      <GamingContainer>
        <ChessLogo width={'148px'} />
        <GameBoard
          intervalSeconds={this.state.intervalSeconds}
          resetGame={this.resetGame}
          isReset={this.state.isReset}
          secondUserStatus={this.state.secondUserStatus}
          fen={this.state.fen}
          onMoveCallback={this.onMoveCallback}
          gameStatus={this.state.gameStatus}
          sendMessage={this.sendMessage}
          hasUnreadMsg={this.state.hasUnreadMsg}
          setAllReadMsg={() => {
            this.setState({
              hasUnreadMsg: false,
            })
          }}
          messagesArray={this.state.messagesArray}
          roomPlayers={this.state.app.gameActivePlayers || []}
          onCapturedPieces={this.onCapturedPieces}
        />
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdlvIjYy1_GC8agnzVQfyacHCC1RUpLrmeF8eGBUvyP1R1awA/viewform?usp=sf_link"
          target={'_blank'}
          className="reportBug"
        >
          {`GameID: ${this.state.app.gameId}`}
          <br />
          Report a bug
        </a>
      </GamingContainer>
    )
  }

  private otherUserTimeout: any = null

  private resetGame = (fen?: string) => {
    this.setState(
      {
        isReset: true,
      },
      () => {
        this.setState({ isReset: false })
        this.state.SocketIo?.emit('board-update', {
          roomId: this.state.app.roomId,
          fen: fen || 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
          orientation: this.state.app.gameOrientation,
          isStart: !fen,
          userSig: this.state.app.gameSignature,
          auth: this.state.app.auth,
        })
      },
    )
  }

  private onMoveCallback = (chessObj) => {
    this.state.SocketIo?.emit('board-update', {
      roomId: this.state.app.roomId,
      fen: chessObj.fen(),
      orientation: this.state.app.gameOrientation,
      isStart: false,
      userSig: this.state.app.gameSignature,
      auth: this.state.app.auth,
    })

    if (!chessObj.in_checkmate()) {
      this.state.SocketIo?.emit('update-timer', {
        roomId: this.state.app.roomId,
        currentTurn: chessObj.turn() === 'w' ? 'b' : 'w',
        auth: this.state.app.auth,
      })
    }
  }

  private updateBoardListener = (data) => {
    const { fen, status, color, isStart } = data

    let message = 'normal'
    let type: 'success' | 'info' | 'warning' | 'error' = 'info'
    let showIcon = false
    let checked = false

    switch (status) {
      case 'check':
        message = `${color === 'w' ? 'White' : 'Black'} in Check`
        type = this.state.app.gameOrientation === color ? 'warning' : 'info'
        checked = true
        showIcon = true
        break
      case 'check_mate':
        message =
          this.state.app.gameOrientation === color ? 'SORRY, YOU LOST.' : `CONGRATS YOU'VE WON!`
        type = this.state.app.gameOrientation === color ? 'error' : 'success'
        showIcon = true
        break
      case 'draw':
        message = 'DRAW'
        type = 'info'
        showIcon = true
        break
      default:
        message = `${color === 'w' ? 'White' : 'Black'}'s Turn`
        break
    }

    const gameStatus = isStart
      ? { ...this.state.gameStatus }
      : {
          message,
          type,
          showIcon,
          checked,
          color: color,
        }
    this.setState({
      fen,
      gameStatus,
    })
  }

  private handleGetBoardInfo = (data) => {
    const _data = data ? JSON.parse(data) : {}
    if (_data && _data.fen) {
      this.resetGame(_data.fen)
      this.setState({ fen: _data.fen })
    }
  }
  private handleRoomCapturedPieces = (capturedPieces) => {
    if (capturedPieces?.length) {
      this.state.dispatch(setGameCapturedPieces([...capturedPieces]))
    }
  }

  private sendMessage = (message: string) => {
    if (message === '') {
      return
    }
    this.state.SocketIo?.emit('message', {
      userName: this.state.currentUser,
      message,
    })
  }

  private newUserListener = (newUserObj) => {
    if (newUserObj?.roomUsers?.length) {
      this.state.dispatch(setGameActivePlayers([...newUserObj.roomUsers]))
    }
    this.setState({
      secondUserStatus: true,
    })
  }

  private newMessageListener = (messageObj) => {
    if (messageObj.userName === this.state.currentUser) {
      messageObj.from = 'self'
    }
    const allMessages = [...this.state.messagesArray, messageObj]
    this.state.dispatch(setMessages({ [this.state.app.gameId || '']: allMessages }))
    this.setState({
      messagesArray: allMessages,
      hasUnreadMsg: messageObj.userName !== this.state.currentUser && messageObj.userName,
    })
  }
}

export default withRouter(Gaming)
