import { motion } from 'framer-motion'
import { RoomItemWrapper } from './styles'
import { RoomItemProps } from './types'
import cn from 'classnames'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { nFormatter } from 'utils'
import { convertUSD } from 'utils/convertUSD'
import { Skeleton } from 'components/Skeleton'
import { useRouterContract } from 'hook/useRouterContract'

export default function RoomItem(props: RoomItemProps) {
  const { room, onClick, roomStatus, onResume } = props
  const [remainSeconds, setRemainSeconds] = useState(60)
  const { price = 0 } = useRouterContract()

  useEffect(() => {
    let interval: any = null
    if (roomStatus && roomStatus.previousRoomTimer) {
      interval = setInterval(() => {
        setRemainSeconds(60 - (moment().unix() - roomStatus.previousRoomTimer.lastUpdate))
      }, 1000)
    }
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [roomStatus])
  return (
    <RoomItemWrapper>
      <div className={'text-center'}>
        <div className={'title'}>{room.title}</div>
        <div className={'subTitle'}>{room.subTitle}</div>
      </div>
      <div>{room.icon}</div>
      <motion.button
        whileHover={{ opacity: 0.65 }}
        className={'joinBtn'}
        onClick={roomStatus && roomStatus.previewsRoomId ? onResume : onClick}
      >
        {roomStatus && roomStatus.previewsRoomId ? `Resume (${remainSeconds})` : 'Join Room'}
      </motion.button>
      <div className={'roomStatus'}>
        <div
          className={cn('betAmt', {
            'custom-bet': room.betAmt === 'Custom',
            'free-bet': room.betAmt === 0,
          })}
        >
          <div>
            {room.betAmt === 'Custom'
              ? 'Custom'
              : room.betAmt === 0
              ? 'Free'
              : `${nFormatter(room.betAmt, 1)}`}
          </div>
          {room.betAmt !== 'Custom' && room.betAmt !== 0 && (
            <>
              {Number(price) === 0 ? (
                <Skeleton width="80px" height="16px" />
              ) : (
                <div className="usdAmt">{` ~$${nFormatter(
                  convertUSD(room.betAmt, price),
                  1,
                )}`}</div>
              )}
            </>
          )}
        </div>

        <div className={'waitingStatus'}>
          <div>Waiting: {roomStatus ? roomStatus.waiting : 0}</div>
          <div>Active Games: {roomStatus ? roomStatus.playing : 0}</div>
        </div>
      </div>
    </RoomItemWrapper>
  )
}
