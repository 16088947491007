import { message, Modal } from 'antd'
import ChatClient from 'components/ChatClient/ChatClient'
import GameStatus from 'components/GameStatus/GameStatus'
import { motion } from 'framer-motion'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { isDesktop } from 'react-device-detect'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import 'stylesheets/RoomDetails.css'
import useActiveWeb3React from '../../hook/useActiveWeb3React'
import { useToast } from '../../hook/useToast'
import { useSocket } from 'contexts/SocketContext'
import {
  setGameEnded,
  setGameSignature,
  setGameTimer,
  setMessages,
  setGameActivePlayers,
  setGameCapturedPieces,
  setGameRoomInfo,
  setRoomId,
} from '../../state/app'
import { formatBlockchainAddress, nFormatter } from '../../utils'
import { useConfirmDrawModal } from '../../widgets/DrawModal'
import ChessBoard from '../Chess/ChessBoard'
import ClockBar from '../ClockBar'
import { Flex } from '../Flex'
import GameActions from '../GameActions'
import { MessengerIcon } from '../Svg'
import { GameBoardContainer } from './styles'
import { State } from 'state/types'

const timerProps = {
  size: 100,
  strokeWidth: 6,
}
const GameBoard = (props) => {
  const app = useSelector((state: State) => state.app)
  const bet = app.bet
  const gameId = app.gameId
  const navigate = useNavigate()
  const { SocketIo } = useSocket()
  const { toastError, toastSuccess } = useToast()
  const { account } = useActiveWeb3React()
  const [startTimer, setStartTimer] = useState(false)
  const [stopTimer, setStopTimer] = useState(false)
  const stopTimerRef = useRef(stopTimer)
  const [timeDuration, setTimeDuration] = useState(60)
  const [turnNumber, setTurnNumber] = useState(0)
  const [endStatus, setEndStatus] = useState(props.gameStatus.message)
  const [expireStatus, setExpireStatus] = useState('')
  const [resignStatus, setResignStatus] = useState('')
  const [myTime, setMyTime] = useState(900)
  const [myTimeStop, setMyTimeStop] = useState(false)
  const [resetTime, setResetTime] = useState(false)
  const [offeringDraw, setOfferingDraw] = useState(false)
  const ChessBoardContainerRef = useRef<any>()
  const [isShowChatView, showChatView] = useState(false)

  const onAcceptDraw = () => {
    SocketIo?.emit('accept-offer-draw', {
      roomId: app.roomId,
      userAddress: app.user,
      userSig: app.gameSignature,
      auth: app.auth,
    })
  }

  const onRejectDraw = () => {
    SocketIo?.emit('reject-offer-draw', {
      roomId: app.roomId,
      userAddress: app.user,
    })
  }
  const { onPresentConfirmDrawModal } = useConfirmDrawModal(onAcceptDraw, onRejectDraw)
  const dispatch = useDispatch()

  const handleUserDisconnected = useCallback(
    (data) => {
      if (data?.userAddress !== account) {
        dispatch(setGameActivePlayers([account]))
      }
    },
    [account, dispatch],
  )

  const newUserListener = useCallback(
    (data) => {
      if (data.roomId == app.roomId && data.allUsersInRoom.length > 1) {
        setStartTimer(false)
        stopTimerRef.current = true
        setStopTimer(true)
        setTurnNumber((prevNum) => prevNum + 1)
      }
    },
    [app.roomId],
  )

  const handleUserRejoined = useCallback(
    ({ roomId, joinedUser, recoveredSignature }) => {
      if (roomId == app.roomId) {
        setStopTimer(true)
        stopTimerRef.current = true
        setStartTimer(false)
      }

      if (joinedUser == account) {
        dispatch(setGameSignature(recoveredSignature))
      }
    },
    [account, app.roomId, dispatch],
  )

  const backToHome = useCallback(
    (path = '/') => {
      dispatch(setRoomId(''))
      dispatch(setMessages({}))
      dispatch(setGameActivePlayers([]))
      dispatch(setGameCapturedPieces([]))
      dispatch(setGameSignature(null))
      dispatch(setGameRoomInfo(null))
      dispatch(setGameEnded(null))

      navigate(path)
    },
    [dispatch, navigate],
  )

  const handleUpdateTimer = useCallback(
    (data) => {
      const address = app.user
      const creator = app.gameRoomInfo.creator
      const myColor = address === creator ? 'w' : 'b'
      const isStopped = data[myColor].stop
      const lastUpdate = data.lastUpdate
      const timestamp = moment().unix()
      const spentTime = timestamp - lastUpdate
      if (isStopped) {
        setMyTime(data[myColor].time)
      } else {
        setMyTime(data[myColor].time - spentTime)
      }
      dispatch(setGameTimer(data))
      setTimeDuration(spentTime > 60 ? 0 : spentTime < 0 ? 60 : 60 - spentTime)
      setMyTimeStop(isStopped)
      if (spentTime < 60) {
        setResetTime((old) => !old)
        setTurnNumber((prevNum) => prevNum + 1)
      }
    },
    [app, dispatch],
  )

  const handleBoardUpdate = useCallback(
    // TODO this could be done from Gaming.tsx, it already has a listern setup for this
    (data) => {
      const { status, isStart } = data
      if (!isStart) {
        setTurnNumber((prevNum) => prevNum + 1)
      }

      switch (status) {
        case 'check_mate':
          const endStatus = 'SORRY, YOU LOST.'
          setEndStatus(endStatus)
          dispatch(setGameEnded(gameId))
          break
        case 'timeout':
          break
        case 'resign':
          break
        case 'draw':
          break
      }
    },
    [dispatch, gameId],
  )

  const handleStopTimer = useCallback(() => {
    setStopTimer(true)
    stopTimerRef.current = true
    setStartTimer(false)
  }, [])

  const handleResignGame = useCallback(
    (data) => {
      setStartTimer(false)
      dispatch(setGameEnded(gameId?.toString()))

      const { roomId, userAddress } = data
      const currentAddress = app.user
      SocketIo?.emit('end-game', { roomId })
      const status = currentAddress !== userAddress ? 'other' : 'me'
      setResignStatus(status)
      dispatch(setGameActivePlayers([]))
    },
    [SocketIo, app.user, dispatch, gameId],
  )

  const handleOfferDraw = useCallback(
    ({ userAddress }) => {
      setOfferingDraw(true)
      if (userAddress !== app.user) {
        onPresentConfirmDrawModal()
      }
    },
    [app.user, onPresentConfirmDrawModal],
  )

  const handleGameEnded = useCallback(
    (data) => {
      const { winAddress, reason, remainSeconds } = data

      switch (reason) {
        case 'check_mate':
          const endStatus = winAddress === account ? "CONGRATS YOU'VE WON!" : 'SORRY, YOU LOST.'
          setEndStatus(endStatus)
          break
        case 'timeout':
          const expireStatus = winAddress === account ? 'win' : 'lost'
          setExpireStatus(expireStatus)
          break
        case 'resign':
          const resignStatus = winAddress === account ? 'other' : 'me'
          setResignStatus(resignStatus)
          break
        case 'draw':
          setEndStatus('DRAW')
          break
      }

      setTimeDuration(remainSeconds)
      setTurnNumber((prev) => prev + 1)
      dispatch(setGameEnded(gameId))

      if (Number(bet) > 0) {
        if (winAddress === account) {
          toastSuccess(
            `Rewards Collected! ${nFormatter(app.gameRoomInfo['betAmount'], 1)} BabyDoge`,
          )
        } else if (reason !== 'draw') {
          toastError('Rewards sent to winner.')
        }
      }

      message.info('Match has been ended.')
    },
    [dispatch, gameId, bet, account, toastSuccess, app.gameRoomInfo, toastError],
  )

  const onExpireTime = useCallback(
    (data) => {
      const { orientation, roomId } = data
      if (roomId === app.roomId) {
        setStartTimer(false)
        setExpireStatus(orientation === app.gameOrientation ? 'lost' : 'win')
        dispatch(setGameEnded(gameId?.toString()))
        dispatch(setMessages({}))
        dispatch(setGameActivePlayers([]))
      }
    },
    [app, dispatch, gameId],
  )

  useEffect(() => {
    SocketIo?.on('user-channel', newUserListener)
    return () => {
      SocketIo?.removeListener('user-channel', newUserListener)
    }
  }, [SocketIo, newUserListener])

  useEffect(() => {
    SocketIo?.on('expire-time', onExpireTime)
    return () => {
      SocketIo?.removeListener('expire-time', onExpireTime)
    }
  }, [SocketIo, onExpireTime])

  useEffect(() => {
    SocketIo?.on('user-disconnected', handleUserDisconnected)
    return () => {
      SocketIo?.removeListener('user-disconnected', handleUserDisconnected)
    }
  }, [SocketIo, handleUserDisconnected])

  useEffect(() => {
    SocketIo?.on('rejoined', handleUserRejoined)
    return () => {
      SocketIo?.removeListener('rejoined', handleUserRejoined)
    }
  }, [SocketIo, handleUserRejoined])

  useEffect(() => {
    SocketIo?.on(`stoptimer`, handleStopTimer)
    return () => {
      SocketIo?.removeListener(`stoptimer`, handleStopTimer)
    }
  }, [SocketIo, handleStopTimer])

  useEffect(() => {
    SocketIo?.on('update-timer', handleUpdateTimer)
    return () => {
      SocketIo?.removeListener('update-timer', handleUpdateTimer)
    }
  }, [SocketIo, handleUpdateTimer])

  useEffect(() => {
    SocketIo?.on('board-update', handleBoardUpdate)
    return () => {
      SocketIo?.removeListener('board-update', handleBoardUpdate)
    }
  }, [SocketIo, handleBoardUpdate])

  useEffect(() => {
    SocketIo?.emit('get-timer', { roomId: app.roomId })
    return () => {
      SocketIo?.removeListener('get-timer')
    }
  }, [SocketIo, app.roomId])

  useEffect(() => {
    if (!gameId) {
      navigate('/')
    }

    SocketIo?.on('resign-game', handleResignGame)
    SocketIo?.on('offer-draw', handleOfferDraw)
    SocketIo?.on('end-game', () => {
      // this looks like its redundant
    })
    SocketIo?.on('game-ended', handleGameEnded)
    SocketIo?.on('reject-offer-draw', () => {
      setOfferingDraw(false)
    })
    if (account && app.gameId) {
      SocketIo?.emit('stoptimer', { gameId: app.gameId })
    }
    SocketIo?.on('connected', () => {
      if (!document.hidden) {
        const roomId = app.roomId
        SocketIo?.emit('rejoined', {
          roomId: roomId,
          joinedUser: app.user,
          auth: app.auth,
        })
        SocketIo?.emit('get-board-info', { roomId, gameId })
        SocketIo?.emit('get-timer', { roomId: roomId })
      }
    })

    return () => {
      SocketIo?.removeAllListeners()
      // dispatch(setGameTimer(null))
      // dispatch(setGameSignature(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setEndStatus(props.gameStatus.message)
  }, [props.gameStatus.message])

  useEffect(() => {
    props.resetGame()

    const handleTabClose = (event) => {
      event.preventDefault()
      return (event.returnValue = 'Are you sure you want to exit?')
    }

    window.addEventListener('beforeunload', handleTabClose)

    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
      if (startTimer) {
        SocketIo?.emit('end-game', {
          roomId: app.roomId,
          gameId,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const localTimer = null
    const cacheTimer = localTimer ? parseInt(localTimer) : 0
    if (cacheTimer > 0) {
      setStartTimer(true)
    }
  }, [startTimer])

  const handleExpireTime = () => {
    // check if game is already ended
    if (!app.gameEnded) {
      const roomId = app.roomId
      const timerData = app.gameTimer || {}
      const currentTurn = Object.keys(timerData).find((key) => !timerData[key].stop)
      SocketIo?.emit('expire-time', { roomId, orientation: currentTurn, auth: app.auth })
    }
  }

  const renderChessPiece = (p, key) => {
    const revertColor = p.color === 'w' ? 'b' : 'w'
    const pieceImg = `/assets/img/chessPieces/${revertColor}${p.captured.toUpperCase()}.png`
    return (
      <div className={'capturePiece'} key={key}>
        <img src={pieceImg} alt={'chess piece'} />
      </div>
    )
  }

  const winnerMessage = (expireStatus, resignStatus) => {
    if (resignStatus) {
      return `VICTORY ACHIEVED!<br/><br/>
      Your opponent has resigned, <br />
      and the ${app.gameOrientation === 'w' ? 'white' : 'black'} pieces triumph!<br/><br/>
      Congratulations, ${app.gameOrientation === 'w' ? 'White' : 'Black'}! <br />
      You are the champion!<br/>
      Game Over<br/><br/>
      Congratulations You Won`
    } else if (expireStatus) {
      return `VICTORY ACHIEVED!<br/><br/>
      Your opponent has timed out, <br />
      and the ${app.gameOrientation === 'w' ? 'white' : 'black'} pieces triumph!<br/><br/>
      Congratulations, ${app.gameOrientation === 'w' ? 'White' : 'Black'}! <br />
      You are the champion!<br/>
      Game Over<br/><br/>
      Congratulations You Won`
    }
    return 'Checkmate! <br/> Congratulations on your victory!'
  }

  const loserMessage = (expireStatus, resignStatus) => {
    if (resignStatus) {
      return `Resigned <br/> <br/> You are resigned!<br/>${
        app.gameOrientation === 'w' ? 'Black' : 'White'
      } Is Victorious<br/>Game Over<br/><br/>SORRY YOU LOST`
    } else if (expireStatus) {
      return `Time Out!<br/>${
        props.gameStatus.color === 'White' ? 'Black' : 'White'
      } Is Victorious<br/>Game Over<br/><br/>SORRY YOU LOST`
    }
    return endStatus
  }

  return (
    <GameBoardContainer>
      <div className="container">
        <Modal
          key="wonModal"
          className="status-modal"
          centered={true}
          closable={false}
          width={300}
          open={
            endStatus === "CONGRATS YOU'VE WON!" ||
            expireStatus === 'win' ||
            resignStatus === 'other'
          }
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          footer={null}
        >
          <div className="game-status-body-container text-center">
            <div className="text-center">
              <div className="loader mb-3">
                <img src={'/img/won.png'} className="loader-img" />
              </div>
              <p
                className="status-text pb-0 mb-0"
                dangerouslySetInnerHTML={{
                  __html: winnerMessage(expireStatus, resignStatus),
                }}
              />
              <p className="status-heading">{nFormatter(app.gameRoomInfo['betAmount'], 1)}</p>
            </div>
            <div className="d-flex justify-content-between ">
              <motion.button
                className="backToHomeBtn"
                onClick={() => backToHome('/')}
                whileHover={{ opacity: 0.65 }}
              >
                Home
              </motion.button>
            </div>
          </div>
        </Modal>

        <Modal
          key="lostModal"
          className="status-modal"
          centered={true}
          closable={false}
          width={300}
          open={
            endStatus === 'SORRY, YOU LOST.' || expireStatus === 'lost' || resignStatus === 'me'
          }
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          footer={null}
        >
          <div className="game-status-body-container text-center">
            <div className="text-center">
              <div className="loader mb-3">
                <img src={'/img/loader.png'} className="loader-img" alt={'loader'} />
              </div>
              <p
                className="status-text pb-0 mb-0"
                dangerouslySetInnerHTML={{
                  __html: loserMessage(expireStatus, resignStatus),
                }}
              />
              <p className="status-heading">BETTER LUCK NEXT TIME.</p>
            </div>
            <div className="d-flex justify-content-center">
              <motion.button
                className={'backToHomeBtn'}
                onClick={() => backToHome('/')}
                whileHover={{ opacity: 0.65 }}
              >
                Return to Home
              </motion.button>
            </div>
          </div>
        </Modal>

        <Modal
          key="drawModal"
          className="status-modal"
          centered={true}
          closable={false}
          width={300}
          open={endStatus === 'DRAW'}
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ style: { display: 'none' } }}
          footer={null}
        >
          <div className="game-status-body-container text-center">
            <div className="text-center">
              <p className="status-text pb-0 mb-0">
                MATCH DRAW!
                <br />
                Both players have agreed to draw the match.
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <motion.button
                className="backToHomeBtn mt-1"
                onClick={() => backToHome('/room-list')}
                whileHover={{ opacity: 0.65 }}
              >
                OK
              </motion.button>
            </div>
          </div>
        </Modal>

        <div className="roomdetails-wrapper">
          <div className="row w-100">
            <div className="col-md-3">
              <ChatClient
                hideChatView={() => {
                  showChatView(false)
                  props.setAllReadMsg()
                }}
                isShowChatView={isShowChatView}
                sendMessage={props.sendMessage}
                messagesArray={props.messagesArray}
              />
            </div>
            <div className="col-md-6 mt-4 mb-4">
              <div className="roomdetails-wrapper-inner">
                <Flex
                  style={{ gridGap: '11px', marginBottom: '14px' }}
                  justifyContent={'space-between'}
                >
                  <ClockBar
                    onEnd={() => handleExpireTime()}
                    orientation={app.gameOrientation === 'w' ? 'w' : 'b'}
                    stopped={myTimeStop || !!app.gameEnded}
                    startTime={myTime}
                    resetTime={resetTime}
                  />

                  <GameStatus
                    message={props.gameStatus.message}
                    type={props.gameStatus.type}
                    showIcon={props.gameStatus.showIcon}
                    checked={props.gameStatus.checked}
                    color={props.gameStatus.color}
                  />
                </Flex>
                <div className="ChessBoardPart" ref={ChessBoardContainerRef}>
                  <ChessBoard
                    boardWidth={ChessBoardContainerRef.current?.offsetWidth ?? 0}
                    fen={props.fen}
                    onMoveCallback={props.onMoveCallback}
                    onCapturedPieces={props.onCapturedPieces}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="gameRightPanel">
                <div>
                  <p className="gameRightPanel-title">Players</p>
                  <div>
                    {app.gameActivePlayers && app.gameActivePlayers.length > 0
                      ? app.gameActivePlayers.map((user, i) => (
                          <Flex key={`user_${user}`} flexDirection="column" alignItems="center">
                            <div>
                              {i === 0 ? (
                                <img
                                  className="playerImg"
                                  src={'/img/player-img.svg'}
                                  width={'20px'}
                                  alt={'player1'}
                                />
                              ) : (
                                <img
                                  className="playerImg"
                                  src={'/img/player-img-1.png'}
                                  width={'20px'}
                                  alt={'player2'}
                                />
                              )}{' '}
                              <span className={'playerAddress'}>
                                {isDesktop
                                  ? formatBlockchainAddress(user, 8, 8)
                                  : formatBlockchainAddress(user, 4, 4)}
                              </span>
                            </div>
                            <Flex
                              className="mb-2"
                              alignItems={'center'}
                              justifyContent={'center'}
                              flexWrap={'wrap'}
                              style={{ maxWidth: '120px', gap: '10px' }}
                            >
                              {app.gameCapturedPieces
                                ?.filter(({ color }) => {
                                  const playerColor = user === app.gameRoomInfo.creator ? 'w' : 'b'
                                  return playerColor === color
                                })
                                ?.map((p, idx) =>
                                  renderChessPiece(
                                    p,
                                    `piece_${p.captured}_${p.color}_${p.flags}_${p.from}_${p.piece}_${p.san}_${p.to}_${idx}`,
                                  ),
                                )}
                            </Flex>
                          </Flex>
                        ))
                      : ''}
                  </div>
                  <hr color={'#002B43'} />
                </div>
                <Flex
                  flexDirection={'column'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  flex={1}
                >
                  <CountdownCircleTimer
                    {...timerProps}
                    key={`timer_${turnNumber}`}
                    isPlaying={!app.gameEnded}
                    duration={60}
                    initialRemainingTime={timeDuration}
                    colors={['#2d6db2', '#A30000']}
                    colorsTime={[10, 0]}
                    trailStrokeWidth={3}
                    onComplete={() => null}
                  >
                    {({ remainingTime }) => {
                      if (remainingTime === 10 && isDesktop) {
                        // alarm.play();
                      }
                      return <span className={'remainingTime'}>{remainingTime}</span>
                    }}
                  </CountdownCircleTimer>

                  {!isDesktop && (
                    <>
                      <motion.div
                        className={'messengerBtn'}
                        onClick={() => {
                          showChatView(true)
                          props.setAllReadMsg()
                        }}
                      >
                        {props.hasUnreadMsg && <div className={'red-dot'} />}
                        <MessengerIcon width={'60px'} />
                      </motion.div>
                    </>
                  )}

                  {isDesktop && (
                    <GameActions
                      offeringDraw={offeringDraw}
                      roomId={app.roomId}
                      userAddress={app.user}
                      onResign={() => setResignStatus('me')}
                    />
                  )}
                </Flex>
              </div>
            </div>
          </div>
        </div>
        {!isDesktop && (
          <GameActions
            offeringDraw={offeringDraw}
            roomId={app.roomId}
            userAddress={app.user}
            onResign={() => setResignStatus('me')}
          />
        )}
      </div>
    </GameBoardContainer>
  )
}

export default GameBoard
