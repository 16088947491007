import { CHAIN_ID, CURRENT_CHAIN } from 'constants/chains'
import { useCallback, useEffect } from 'react'
import { useConfig, useConnect, useSwitchNetwork } from 'wagmi'

export const useHandleEagerConnect = () => {
  const client = useConfig()
  const { connectAsync, connectors } = useConnect()
  const { switchNetwork } = useSwitchNetwork()

  // function to auto connect to wagmi provider safely
  // if as fallback refresh the page for user
  const safelyAutoConnect = useCallback(async () => {
    try {
      await client.autoConnect()
      const chainId = client.connector ? await client.connector.getChainId() : -1
      if (chainId === -1 || (chainId !== CHAIN_ID.BSC && chainId !== CHAIN_ID.BSC_TESTNET)) {
      }
    } catch {
      window.location.reload()
    }
  }, [client])

  return useCallback(async () => {
    // try to find a safe connector
    const connector = connectors.find((c) => c.ready)
    try {
      // only attempt connectAsync when a connector is found
      if (connector) {
        await connectAsync({ connector })
        const chainId = connector ? await connector.getChainId() : -1
        if (chainId === -1 || chainId !== CURRENT_CHAIN) {
          switchNetwork && switchNetwork()
        }
      } else {
        await safelyAutoConnect()
      }
    } catch (err) {
      // in cases where connectAsync fails, fallback to safelyAutoConnect
      if (connector) {
        await safelyAutoConnect()
      }
    }
  }, [connectors, connectAsync, safelyAutoConnect, switchNetwork])
}

export const useEagerConnect = () => {
  const handleEagerConnect = useHandleEagerConnect()

  useEffect(() => {
    handleEagerConnect()
  }, [handleEagerConnect])
}
