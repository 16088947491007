import { motion } from 'framer-motion'
import React from 'react'
import { useWalletModal } from '../WalletModal'
import { Login } from '../WalletModal/types'
import { formatBlockchainAddress } from '../../utils'

interface Props {
  account: `0x${string}` | null | undefined
  login: Login
  logout: () => void
}

const UserBlock: React.FC<Props> = ({ account, login, logout }) => {
  const { onPresentConnectModal } = useWalletModal(login)
  const loadButton = () => {
    if (account) {
      return (
        <motion.button onClick={logout} className={'walletBtn'} whileHover={{ opacity: 0.65 }}>
          <span>{formatBlockchainAddress(account, 4, 4)}</span>
          <svg
            viewBox="0 0 24 24"
            width="12px"
            xmlns="http://www.w3.org/2000/svg"
            className="sc-bdnxRM ACFFk"
            fill={'#fff'}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17 4C18.5 4 19 4.5 19 6L19 8C20.1046 8 21 8.89543 21 10L21 17C21 19 20 20 17.999 20H6C4 20 3 19 3 17L3 7C3 5.5 4.5 4 6 4L17 4ZM5 7C5 6.44772 5.44772 6 6 6L19 6L19 8L6 8C5.44772 8 5 7.55229 5 7ZM17 16C18 16 19.001 15 19 14C18.999 13 18 12 17 12C16 12 15 13 15 14C15 15 16 16 17 16Z"
              fill={'#fff'}
            />
          </svg>
        </motion.button>
      )
    }
    return (
      <motion.button
        className={'connectWalletBtn'}
        whileHover={{ opacity: 0.65 }}
        onClick={() => onPresentConnectModal()}
      >
        Connect Wallet
      </motion.button>
    )
  }
  return <div>{loadButton()}</div>
}

export default UserBlock
