import React from 'react'
import { Login } from 'components/WalletModal/types'
import CustomModal from '../Modal/Modal'
import { connectors, localStorageKey } from './config'
import { Flex } from 'components/Flex'

interface Props {
  login: Login
  onDismiss?: () => void
}

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null }) => (
  <CustomModal title="Connect wallet" onDismiss={onDismiss} width="360px">
    <div className="room-info-container">
      <div className="room-info-body">
        <div className="beta-description">
          Smart contract has been audited, the game is in Beta. BabyDoge is not liable for any loss
          of funds, deposit at your own risk.
        </div>
        <div className="row">
          {connectors.map((entry, index) => {
            return (
              <div className="col-12 mb-2 mt-2" key={`wallet_${index}`}>
                <span
                  className="wallet__icon d-flex justify-content-center align-items-center p-2 "
                  key={`${index}`}
                  onClick={() => {
                    if (!window.ethereum && entry.href) {
                      window.open(entry.href, '_blank', 'noopener noreferrer')
                    } else {
                      login(entry.connectorId)
                      window.localStorage.setItem(localStorageKey, entry.connectorId)
                      onDismiss()
                    }
                  }}
                >
                  <entry.icon name={entry.title} />
                </span>
              </div>
            )
          })}
        </div>
      </div>

      <Flex
        flexDirection={'column'}
        alignItems="center"
        className="room-info-button-container justify-content-between mt-4"
      >
        <div className="nowallet">Haven't got a crypto wallet yet?</div>

        <a
          href="https://docs.babydogeswap.com/babydoge-docs/get-started"
          className="learnConnect"
          target={'_blank'}
        >
          Learn how to connect
        </a>
      </Flex>
    </div>
  </CustomModal>
)

export default ConnectModal
