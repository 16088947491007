import styled from 'styled-components'

export const GameActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  justify-content: center;

  .resignBtn {
    width: 102px;
    height: 27px;
    border-radius: 13.5px;
    background: #bf4040;
    border: 1px solid #bf4040;
    font-family: 'Poppins', serif;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
  }

  .drawBtn {
    width: 102px;
    height: 27px;
    border-radius: 13.5px;
    background: transparent;
    border: 1px solid #fff;
    font-family: 'Poppins', serif;
    font-weight: bold;
    font-size: 11px;
    letter-spacing: 0.05em;
    text-align: center;
    color: #fff;
  }

  @media (max-width: 780px) {
    flex-direction: row-reverse;
    margin-top: 20px;
    margin-bottom: 20px;
    .resignBtn {
      max-width: 172px;
      height: 48px;
      border-radius: 24px;
      font-size: 18px;
      flex: 1;
    }
    .drawBtn {
      max-width: 172px;
      height: 48px;
      border-radius: 24px;
      font-size: 18px;
      flex: 1;
    }
  }
`
