import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 81 81" {...props}>
      <defs>
        <clipPath id="clip-path-messenger">
          <rect
            id="Rectangle_972"
            data-name="Rectangle 972"
            width="39.218"
            height="35.227"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_2544" data-name="Group 2544" transform="translate(-352 -639)">
        <circle
          id="Ellipse_4"
          data-name="Ellipse 4"
          cx="40.5"
          cy="40.5"
          r="40.5"
          transform="translate(352 639)"
          fill="#0a88e5"
        />
        <g id="Group_2540" data-name="Group 2540" transform="translate(372.654 663.973)">
          <g id="Group_2539" data-name="Group 2539" clipPath="url(#clip-path-messenger)">
            <path
              id="Path_1136"
              data-name="Path 1136"
              d="M27.4,0H11.817A11.817,11.817,0,0,0,0,11.817v9.247a9,9,0,0,0,8.021,8.942l-.087,4.45a.755.755,0,0,0,1.155.654l8.055-5.051H27.4A11.817,11.817,0,0,0,39.219,18.243V11.817A11.817,11.817,0,0,0,27.4,0"
              transform="translate(0 0)"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </Svg>
  )
}

export default Icon
