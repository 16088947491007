import UserBlock from 'components/Menu/UserBlock'
import { NavProps } from 'components/Menu/types'
import useActiveWeb3React from 'hook/useActiveWeb3React'
import * as React from 'react'
import { Flex } from '../Flex'
import { LogoIcon } from '../Svg'
import { MenuContainer } from './styles'

// const TopUpMenu = () => {
//   const { mint } = useERC20(getBabydogeAddress())
//   return (
//     <>
//       <motion.button
//         className={'leaderBoardBtn'}
//         whileHover={{ opacity: 0.65 }}
//         onClick={() => window.open('https://testnet.bnbchain.org/faucet-smart', '_blank')}
//       >
//         BNB Faucet
//       </motion.button>
//       <motion.button
//         className={'leaderBoardBtn'}
//         whileHover={{ opacity: 0.65 }}
//         disabled={!mint.write}
//         onClick={() => mint.write && mint.write()}
//       >
//         TopUp
//       </motion.button>
//     </>
//   )
// }

const Menu: React.FC<NavProps> = ({ login, logout }) => {
  // const navigate = useNavigate()
  const { account } = useActiveWeb3React()

  return (
    <MenuContainer>
      <div className={'menuWrapper'}>
        <a href={'/'}>
          <LogoIcon width={'30px'} />
        </a>
        <Flex style={{ gridGap: '9px' }} flexWrap="wrap">
          {/* {isDesktop && account && chainId == CHAIN_ID.BSC_TESTNET && <TopUpMenu />} */}
          {/* {account && (
            <motion.button
              className={'leaderBoardBtn'}
              whileHover={{ opacity: 0.65 }}
              onClick={() => navigate('/leader-board')}
            >
              My Stats
            </motion.button>
          )} */}
          <UserBlock account={account} login={login} logout={logout} />
        </Flex>
      </div>
    </MenuContainer>
  )
}

export default Menu
